<template>
  <v-container fluid>
    <v-card>
      <v-card class="mx-auto">
        <v-toolbar color="toolbar" class="toolbarText--text">
          <v-toolbar-title style="width: 100%">Changelog </v-toolbar-title>
        </v-toolbar>
      </v-card>
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <v-icon color="red">mdi-bug</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>Changelog</v-list-item-title>
            <v-list-item-subtitle
              >New features and resolved bugs will be displayed
              here.</v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list dense two-line>
        <template v-for="(item, index) in parsedChangeLog">
          <template>
            <v-list-item :key="item.title">
              <template v-slot:default>
                <v-list-item-avatar>
                  <v-icon color="green">mdi-check-circle</v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title v-text="item.title"></v-list-item-title>

                  <v-list-item-subtitle
                    v-text="item.description"
                  ></v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <v-list-item-action-text
                    v-text="item.date"
                  ></v-list-item-action-text>
                </v-list-item-action>
              </template>
            </v-list-item>
            <v-divider
              v-if="index < parsedChangeLog.length - 1"
              :key="index"
            ></v-divider>
          </template>
        </template>
      </v-list>
    </v-card>
  </v-container>
</template>

<script>
import { makeFindMixin } from "feathers-vuex"

import moment from "moment"
export default {
  name: "Changelog",
  mixins: [
    makeFindMixin({
      service: "changelog",
    }),
  ],
  data: () => ({}),
  computed: {
    changelogParams() {
      return {
        query: {
          $limit: 1000,
        },
      }
    },
    parsedChangeLog() {
      return this.changelog.map((item) => {
        return {
          ...item,
          date: moment(item.date).fromNow(),
        }
      })
    },
  },
}
</script>
