import feathersClient, {
  makeServicePlugin,
  BaseModel,
} from "../../../feathers-client"

class TimesheetSubmission extends BaseModel {
  constructor(data, options) {
    super(data, options)
  }
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = "TimesheetSubmission"
  // Define default properties here
  static instanceDefaults() {
    return {}
  }
}
const servicePath = "mavenlink/timesheet-submission"
const servicePlugin = makeServicePlugin({
  Model: TimesheetSubmission,
  namespace: "mavenlink/timesheet-submission",
  service: feathersClient.service(servicePath),
  servicePath,
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [
      (context) => {
        context.result = context.result.map(item => {
          return {
            ...item,
            date_range: `${item.start_date} - ${item.end_date}`,
          }
        }).sort((a, b) => {
          return new Date(b.start_date) - new Date(a.start_date)
        }).reverse()
        return context
      }
    ],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
})

export default servicePlugin
