import feathersClient, {
  makeServicePlugin,
  BaseModel
} from "../../../feathers-client"

class Project extends BaseModel {
  constructor(data, options) {
    super(data, options)
  }
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = "Project"
  // Define default properties here
  static instanceDefaults() {
    return {}
  }
}
const servicePath = "redmine/project"
const servicePlugin = makeServicePlugin({
  Model: Project,
  namespace: "redmine/project",
  service: feathersClient.service(servicePath),
  servicePath
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: context => {
      context.params.query = Object.assign(
        context.params.query ? context.params.query : {},
        {
          assigned_to_id: "me"
        }
      )
      return context
    },
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin
