<template>
  <v-app id="inspire">
    <v-navigation-drawer
      v-model="drawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
      app
      v-if="userIsOnboarded"
    >
      <div class="d-flex flex-column" style="height: 100%">
        <v-overlay :absolute="true" :value="!userIsOnboarded">
          Please complete initial setup.
        </v-overlay>
        <v-list align-start nav dense>
          <v-list-item-group v-model="nav">
            <template v-for="item in items">
              <v-list-item
                v-if="!item.admin || (item.admin && user && user.admin)"
                @click="goTo(item.path)"
                :key="item.path"
                :value="item.path"
                link
                :disabled="item.divider || item.disabled"
              >
                <v-divider v-if="item.divider"></v-divider>
                <template v-else>
                  <v-list-item-action>
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.text }}
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-list-item>
            </template>
          </v-list-item-group>
        </v-list>
        <TodayNav v-if="user && user.id" class="mt-auto" />
      </div>
    </v-navigation-drawer>

    <v-app-bar
      class="white--text"
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      :clipped-right="$vuetify.breakpoint.lgAndUp"
      app
      color="toolbar"
      :elevate-on-scroll="true"
    >
      <v-app-bar-nav-icon
        v-if="userIsOnboarded"
        class="white--text"
        @click.stop="drawer = !drawer"
      />
      <v-app-bar-nav-icon>
        <v-avatar>
          <img src="/logo_white.png" alt="" />
        </v-avatar>
      </v-app-bar-nav-icon>
      <v-toolbar-title class="ml-0 pl-4">
        <span
          transition="scale-transition"
          key="0"
          v-show="!driving"
          class="hidden-sm-and-down"
          >{{ title }}</span
        >
        <span key="1" v-show="driving === 1" class="hidden-sm-and-down"
          ><b class="accent--text">D</b>RIVE - Demonstrate thoughtful
          communication.</span
        >
        <span key="2" v-show="driving === 2" class="hidden-sm-and-down"
          >D<b class="accent--text">R</b>IVE - Rise to the occasion.</span
        >
        <span key="3" v-show="driving === 3" class="hidden-sm-and-down"
          >DR<b class="accent--text">I</b>VE - Integrity all the time.</span
        >
        <span key="4" v-show="driving === 4" class="hidden-sm-and-down"
          >DRI<b class="accent--text">V</b>E - Value to clients.</span
        >
        <span key="5" v-show="driving === 5" class="hidden-sm-and-down"
          >DRIV<b class="accent--text">E</b> - Excel at your craft.</span
        >
        <v-btn
          v-if="driving"
          @click="randomValue"
          class="toolbarText--text hidden-sm-and-down"
          icon
        >
          <v-icon> mdi-refresh </v-icon>
        </v-btn>
      </v-toolbar-title>
      <v-spacer />

      <div
        class="d-inline-flex align-center justify-content-center justify-center d-flex"
        v-if="user"
      >
        <v-btn-toggle
          v-if="enableRedmine && showIssueTracker"
          class="mr-2"
          v-model="issueTracker"
        >
          <v-btn
            :small="$vuetify.breakpoint.mdAndUp"
            :icon="$vuetify.breakpoint.smAndDown"
            value="redmine"
          >
            <span class="hidden-sm-and-down">Redmine</span>
            <v-avatar class="hidden-md-and-up">
              <img
                src="https://redmine.orases.com/favicon.ico"
                style="width: 25px; height: 25px"
                alt=""
              />
            </v-avatar>
          </v-btn>

          <v-btn
            :icon="$vuetify.breakpoint.smAndDown"
            :small="$vuetify.breakpoint.mdAndUp"
            value="jira"
          >
            <span class="hidden-sm-and-down">Jira</span>
            <v-avatar class="hidden-md-and-up">
              <img
                src="https://orases.atlassian.net/favicon.ico"
                style="width: 25px; height: 25px"
                alt=""
              />
            </v-avatar>
          </v-btn>
        </v-btn-toggle>
        <v-btn
          v-if="$vuetify.breakpoint.mdAndUp"
          :color="
            isTimesheetDueSoon && !isTimesheetSubmitted && !hasPendingTimesheet
              ? 'warning'
              : 'success'
          "
          class="mr-4"
          small
          to="/timesheet-submissions"
          >{{ submitTimesheetTitle }}
          <v-icon v-if="hasPendingTimesheet" class="ml-1"
            >mdi-timer-sand-paused</v-icon
          >
        </v-btn>
        <span
          v-if="$vuetify.breakpoint.mdAndUp"
          class="pr-5 align-center justify-content-center justify-center d-flex"
          >{{ user.firstName }}</span
        >
      </div>
      <v-spacer v-if="!$vuetify.breakpoint.mdAndUp" />
      <div v-if="$store.state.auth.user">
        <v-avatar v-if="user._id">
          <img :src="user.profilePicture" :alt="user.firstName" />
        </v-avatar>
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn class="white--text" v-on="on" icon>
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list two-line min-width="500" subheader>
            <v-subheader>Quick Settings</v-subheader>
            <v-divider></v-divider>
            <v-list-item>
              <v-switch v-model="darkMode" label="Dark Mode"></v-switch>
            </v-list-item>
            <v-list-item>
              <v-switch
                v-model="showWeekend"
                label="Show Weekend Days"
              ></v-switch>
            </v-list-item>
            <v-list-item>
              <v-switch
                v-model="hideDeclinedMeetings"
                label="Hide Declined Meetings"
              ></v-switch>
            </v-list-item>

            <v-subheader>System Information</v-subheader>
            <v-divider></v-divider>
            <v-list-item>
              <v-list-item-title> Last MavenLink Sync </v-list-item-title>
              <v-list-item-action> {{ lastMavenlinkSync }} </v-list-item-action>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item @click="logout">
              <v-list-item-title> Logout </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>

    <v-main v-if="$store.state.auth.user">
      <InitialSetup v-if="!userIsOnboarded" />
      <template v-else>
        <v-container fluid v-if="$route.meta.summary">
          <WeeklySummary />
        </v-container>
        <v-container fluid>
          <router-view />
        </v-container>
        <TimeDialog v-if="dialogPlacement === 'modal'" />
        <TimeSidebar v-else />
      </template>
    </v-main>
    <LoginModal v-else />
    <div class="text-center">
      <v-snackbar
        dark
        :color="toastColor"
        bottom
        right
        v-model="showToast"
        :timeout="toastTimeout"
      >
        {{ toastMessage }}
        <v-btn text @click="showToast = false"> Close </v-btn>
      </v-snackbar>
    </div>
    <v-dialog
      overlay-opacity="0.9"
      v-model="reloadDialog"
      persistent
      width="300"
    >
      <v-card color="primary" dark>
        <v-card-title> Reload needed. </v-card-title>
        <v-card-text>
          A new version of Time Helper has been detected. Your browser will
          reload in <strong>{{ reloadSeconds }}</strong> seconds.
          <v-progress-linear
            :value="reloadSeconds * 10"
            color="white"
            class="mt-2 mb-0"
          ></v-progress-linear>
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-spacer></v-spacer>
          <v-btn @click="reloadPage()" text>Reload Now</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { mapGetters, mapState } from "vuex"
import { mapFields } from "vuex-map-fields"
import LoginModal from "@/components/LoginModal"
import TimeDialog from "@/components/TimeDialog"
import InitialSetup from "@/components/InitialSetup"
import WeeklySummary from "@/components/WeeklySummary"
import TodayNav from "@/components/TodayNav"
import TimeSidebar from "@/components/TimeSidebar"
import moment from "moment"
import { makeFindMixin } from "feathers-vuex"

export default {
  props: {
    source: String,
  },
  mixins: [
    makeFindMixin({
      service: "mavenlink/timesheet-submission",
    }),
    makeFindMixin({
      service: "pending-timesheets",
    }),
  ],
  computed: {
    ...mapGetters(["userIsOnboarded"]),
    ...mapFields(["enableRedmine", "userRole"]),
    ...mapFields(["startDate", "endDate"]),
    ...mapFields("userPreferences", [
      "darkMode",
      "showWeekend",
      "dialogPlacement",
      "issueTracker",
      "hideDeclinedMeetings",
      "showIssueTracker",
    ]),
    submitTimesheetTitle() {
      let title = "Submit Timesheet"

      if (this.isTimesheetDueSoon && !this.isTimesheetSubmitted) {
        title = "Submit Timesheet (Due Soon)"
      }

      if (this.isTimesheetSubmitted) {
        title = "Timesheet Submitted"
      }

      if (this.hasPendingTimesheet) {
        title = "Timesheet Submission Queued"
      }

      return title
    },
    isTimesheetDueSoon() {
      return moment().isSameOrAfter(
        moment(this.endDate).subtract(1, "day"),
        "day"
      )
    },
    currentTimesheetSubmissions() {
      return this.timesheetSubmission.filter((t) => {
        return (
          moment(t.start_date).isSameOrAfter(this.startDate, "day") &&
          moment(t.end_date).isSameOrBefore(this.endDate, "day")
        )
      })
    },
    isTimesheetSubmitted() {
      return this.currentTimesheetSubmissions.length > 0
    },
    hasPendingTimesheet() {
      return this.pendingTimesheets.length > 0 && !this.isTimesheetSubmitted
    },
    pendingTimesheet() {
      if (!this.pendingTimesheets.length) {
        return false
      }

      return this.pendingTimesheets[0]
    },
    pendingTimesheetsParams() {
      const personId = this.$store.state.auth?.user?.mavenlinkId || 0
      const startDate = moment(this.startDate).format("YYYY-MM-DD")
      const endDate = moment(this.endDate).format("YYYY-MM-DD")
      return { query: { startDate, endDate, personId, $limit: 200 } }
    },
    showToast: {
      get() {
        return this.$store.state.showToast
      },
      set(newValue) {
        return this.$store.dispatch("toggleToast", newValue)
      },
    },
    ...mapState({
      user: (state) => state.auth.user,
      auth: (state) => state.auth,
      showInitialSetup: (state) => state.showInitialSetup,
      toastMessage: (state) => state.toastMessage,
      toastTimeout: (state) => state.toastTimeout,
      toastColor: (state) => state.toastColor,
    }),
    timesheetSubmissionParams() {
      return { query: { $limit: 50, $sort: { id: -1 } } }
    },
  },
  data: () => ({
    lastMavenlinkSync: "--",
    driving: false,
    value: "DRIVE",
    title: "Time Helper",
    reloadSeconds: 0,
    reloadDialog: false,
    nav: 0,
    dialog: false,
    drawer: null,
    items: [
      { icon: "mdi-view-dashboard", text: "Dashboard", path: "/" },
      { icon: "mdi-history", text: "Logs", path: "/logs" },
      {
        icon: "mdi-calendar",
        text: "Timesheets",
        path: "/timesheet-submissions",
      },
      {
        icon: "mdi-priority-high",
        text: "Priorities",
        path: "/priorities",
      },
      { icon: "mdi-cogs", text: "Settings", path: "/settings" },
      { divider: true },
      { icon: "mdi-bug", text: "Changelog", path: "/changelog" },
      { icon: "mdi-account", text: "Users", path: "/users", admin: true },
    ],
  }),
  methods: {
    randomValue() {
      let cachedValue = this.driving
      this.driving = Math.floor(Math.random() * 5) + 1
      if (cachedValue === this.driving) {
        this.randomValue()
      }
    },
    reloadPage() {
      window.location.reload()
    },
    reload() {
      this.reloadDialog = true
      this.reloadSeconds = 10

      let reloadInterval = setInterval(() => {
        this.reloadSeconds--
        if (this.reloadSeconds <= 0) {
          clearInterval(reloadInterval)
          window.location.reload()
        }
      }, 1000)
    },
    logout() {
      this.$store.dispatch("auth/logout")
    },
    goTo(path) {
      this.$router.push({ path: path })
    },
  },
  components: {
    LoginModal,
    InitialSetup,
    TimeDialog,
    WeeklySummary,
    TodayNav,
    TimeSidebar,
  },
  created() {
    setInterval(() => {
      this.driving = Math.floor(Math.random() * 5) + 1
    }, 30000)
  },
  mounted() {
    this.nav = this.$route.path
    let $this = this
    if (this.darkMode) {
      this.$vuetify.theme.dark = true
    }
    let serverStart = false

    window.socket.on("mavenlink synced", (data) => {
      if (data) {
        this.lastMavenlinkSync = moment(data).format("MM/DD/YY h:mm:ss a")
      }
    })

    window.socket.on("users status", (data) => {
      if (data.type === "lastServerStart") {
        if (serverStart && serverStart !== data.data) {
          $this.reload()
        }
        serverStart = data.data
      }
    })

    this.$store.dispatch("mavenlink/me/find", {}).then((r) => {
      if (r.length) {
        this.userRole = r[0].role
        if (this.user.disableIssueTracker) {
          this.showIssueTracker = false
        }
      }
    })

    this.startDate = moment().startOf("week").format("YYYY-MM-DD")
    this.endDate = moment().endOf("week").format("YYYY-MM-DD")
  },
  watch: {
    darkMode(newValue) {
      this.$vuetify.theme.dark = newValue
    },
  },
}
</script>
