import Vue from "vue"
import Vuetify from "vuetify/lib"

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#233746",
        secondary: "#404040",
        accent: "#bfd42f",
        orasesBlue: "#167099",
        success: "#428444",
        base: "#000000",
        toolbar: "#243646",
        toolbarText: "#fff",
        progressColor: "#233746",
        progressColorComplete: "#bfd42f",
        text: "#fff",
      },
      dark: {
        primary: "#136e98",
        secondary: "#404040",
        accent: "#bfd42f",
        orasesBlue: "#167099",
        success: "#428444",
        toolbar: "#292929",
        toolbarText: "#fff",
        progressColor: "#fff",
        progressColorComplete: "#bfd42f",
        text: "#fff",
      },
    },
  },
})
