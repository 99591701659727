<template>
  <v-container fluid>
    <v-card>
      <v-card-text>
        <v-card class="mb-12">
          <v-card-title class="headline">Let's get started!</v-card-title>

          <v-card-subtitle
            >In just a few short steps you'll be ready to use Time
            Helper!</v-card-subtitle
          >
        </v-card>
        Please link the below services to your Time Helper account.
      </v-card-text>

      <v-container fluid>
        <v-row>
          <v-col>
            <v-card outlined>
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="text-overline mb-4">MavenLink</div>
                  <v-list-item-title class="text-h5 mb-1">
                    {{ user.mavenlinkAccessToken ? "Linked" : "Not Linked" }}
                  </v-list-item-title>
                </v-list-item-content>

                <v-list-item-avatar tile size="35">
                  <img
                    src="https://images.squarespace-cdn.com/content/v1/551d16d4e4b0f5016a218c6e/1437721380466-NK2V6FF0L3TNNHN6OYHW/image-asset.png?format=750w"
                    alt=""
                  />
                </v-list-item-avatar>
              </v-list-item>
              <v-card-text>
                Click the "Login" button below, you will be prompted to login to
                your MavenLink account. Select "Google" single sign on when
                prompted to login.
              </v-card-text>

              <v-card-actions>
                <v-btn
                  block
                  :href="`${loginUrl}oauth/mavenlink?feathers_token=${accessToken}`"
                >
                  Login
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col>
            <v-card outlined>
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="text-overline mb-4">Jira</div>
                  <v-list-item-title class="text-h5 mb-1">
                    {{
                      user.jiraApiKey && user.jiraApiKey.length > 0
                        ? "Linked"
                        : "Not Linked"
                    }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-avatar tile size="35">
                  <img
                    src="https://cdn.icon-icons.com/icons2/2699/PNG/512/atlassian_jira_logo_icon_170511.png"
                    alt=""
                  />
                </v-list-item-avatar>
              </v-list-item>
              <v-card-text>
                Please navigate to
                <a
                  target="_blank"
                  href="https://id.atlassian.com/manage-profile/security/api-tokens"
                >
                  this URL</a
                >
                and create an API token, then copy and paste it into the field
                below.
                <v-text-field
                  @keyup="valid = true"
                  v-model="userClone.jiraApiKey"
                  label="Jira API Token"
                ></v-text-field>
              </v-card-text>

              <v-card-actions>
                <v-btn block @click="saveUser" color="success">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col v-if="enableRedmine">
            <v-card outlined>
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="text-overline mb-4">Redmine</div>
                  <v-list-item-title class="text-h5 mb-1">
                    {{ user.redmineApiKey ? "Linked" : "Not Linked" }}
                  </v-list-item-title>
                </v-list-item-content>

                <v-list-item-avatar tile size="35">
                  <img
                    src="https://pbs.twimg.com/profile_images/754993362378383360/SSxJOYIY_400x400.jpg"
                    alt=""
                  />
                </v-list-item-avatar>
              </v-list-item>

              <v-card-text>
                Please navigate
                <a target="_blank" href="https://redmine.orases.com/my/account"
                  >to this URL</a
                >
                and copy your API Access Token in the right panel under "My
                account". You may need to click the "Show" button.
                <v-text-field
                  @keyup="valid = true"
                  v-model="userClone.redmineApiKey"
                  label="Redmine Access Token"
                  class="mt-5 mb-5"
                  :success-messages="redmineSuccess"
                  :error-messages="redmineError"
                ></v-text-field>
              </v-card-text>

              <v-card-actions>
                <v-btn block color="success" @click="saveUser">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters, mapState } from "vuex"
import { mapFields } from "vuex-map-fields"

export default {
  data: () => ({
    step: 1,
    redmineSuccess: "",
    redmineError: "",
    redmineApiKey: "",
    redmineValidated: false,
    userClone: {},
  }),
  computed: {
    ...mapState({
      accessToken: (state) => state.auth.accessToken,
    }),
    ...mapFields({
      enableRedmine: "enableRedmine",
    }),
    ...mapGetters(["loginUrl", "userIsOnboarded"]),
    ...mapGetters("auth", ["user"]),
  },
  mounted() {
    this.redmineApiKey = this.user.redmineApiKey
    this.userClone = { ...this.user }
  },
  methods: {
    saveUser() {
      this.$store
        .dispatch("users/patch", [this.userClone._id, { ...this.userClone }])
        .then(() => {
          if (this.userIsOnboarded) {
            this.$store.dispatch("showToastSuccess", "Successfully onboarded!")
          }
        })
    },
    finalizeSetup() {
      // I'm being hacky until I fix a state issue
      window.location.reload()
    },
    validateRedmine() {
      this.$store
        .dispatch("redmine/me/find", {
          query: {
            apiKey: this.userClone.redmineApiKey,
          },
        })
        .then((me) => {
          this.redmineError = ""
          this.redmineSuccess = `You're all set with Redmine, ${me[0].firstname}.`

          this.$store
            .dispatch("users/patch", [
              this.$store.state.auth.user._id,
              {
                redmineApiKey: this.redmineApiKey,
              },
            ])
            .then(() => {
              this.redmineValidated = true
            })
        })
        .catch(() => {
          this.redmineError = `Uh, oh. We couldn't connect to your redmine account. Check your API key and try again.`
        })
    },
  },
}
</script>
