var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"d-flex w-100 pa-2",staticStyle:{"width":"100%","flex-direction":"column"},attrs:{"flat":"","color":_vm.isFilterEnabled(_vm.summary)
          ? _vm.$vuetify.theme.dark
            ? 'grey darken-3'
            : 'grey lighten-5'
          : _vm.$vuetify.theme.dark
          ? 'grey darken-4'
          : 'white',"elevation":_vm.isFilterEnabled(_vm.summary) ? 2 : 0}},[(
          !_vm.isPrioritiesPending &&
          _vm.summary.priorities.length &&
          _vm.$route.name === 'Dashboard'
        )?_c('v-fade-transition',[(hover && 1 === 2)?_c('v-overlay',{attrs:{"absolute":"","color":"#3b3b3b"}},[_c('v-btn',{attrs:{"color":"secondary"},on:{"click":function($event){return _vm.filterByPriority(_vm.summary.date)}}},[(!_vm.isFilterEnabled(_vm.summary))?[_vm._v(" Filter Redmines "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-filter")])]:[_vm._v(" Disable Filter "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-filter-off")])]],2)],1):_vm._e()],1):_vm._e(),_c('v-progress-circular',{staticClass:"mx-auto font-weight-bold",attrs:{"rotate":270,"size":_vm.compact ? 65 : 70,"width":_vm.compact ? 5 : 7,"value":(_vm.summary.totalTime / (_vm.summary.totalPriorities || 8)) * 100,"color":_vm.summary.totalTime < (_vm.summary.totalPriorities || 8)
            ? 'progressColor'
            : 'progressColorComplete'}},[[_c('div',{staticClass:"d-flex text-center",staticStyle:{"width":"100%","flex-direction":"column"}},[(!_vm.summary.time_off)?[_c('span',[_vm._v(" "+_vm._s(_vm.summary.totalTime)+" ")]),(_vm.compact)?_c('small',[_vm._v(_vm._s(_vm.summary.dayShort))]):_vm._e()]:[_c('span',{staticStyle:{"font-size":"2rem"}},[_vm._v(" ⛱️ ")])]],2)]],2),(!_vm.compact)?_c('div',{staticClass:"mx-auto text-center",staticStyle:{"width":"80%"}},[_vm._v(" "+_vm._s(_vm.summary.dateShort)+" "),(_vm.isPrioritiesPending)?_c('v-progress-linear',{attrs:{"color":"accent","indeterminate":"","rounded":"","height":"6"}}):_vm._e(),(!_vm.isPrioritiesPending)?_c('v-row',{staticClass:"pt-2",attrs:{"no-gutters":"","justify":"center","align":"center"}},_vm._l((_vm.summary.priorities),function(priority){return _c('v-col',{key:((priority.workspace_id) + "-" + (priority.date)),attrs:{"cols":"12"}},[_c('v-badge',{staticStyle:{"width":"100%"},attrs:{"overlap":"","color":priority.color},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('span',{class:'font-weight-bold ' +
                    (_vm.lightText(priority.color)
                      ? 'black--text'
                      : 'white--text')},[_vm._v(_vm._s(priority.hours)+" H")])]},proxy:true}],null,true)},[_c('v-chip',{class:("mt-1 font-weight-bold " + (_vm.lightText(priority.color) ? 'black--text' : 'white--text')),staticStyle:{"width":"100%"},attrs:{"small":"","label":"","color":priority.color}},[_vm._v(" "+_vm._s(_vm.truncate(priority.workspace_title, 20))+" ")])],1)],1)}),1):_vm._e()],1):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }