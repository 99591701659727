<template>
  <div class="mt-2">
    <v-autocomplete
      label="Projects"
      :items="workspaces"
      item-text="title"
      item-value="id"
      multiple
      solo
      class="ml-2"
      v-model="mavenlinkProjectFilter"
      clearable
    ></v-autocomplete>
  </div>
</template>

<script>
import { mapFields } from "vuex-map-fields"
export default {
  name: "RedminesWidget",
  props: {
    statuses: Array,
    projects: Array,
    workspaces: Array,
    workspaceGroups: Array,
  },
  computed: {
    ...mapFields("userPreferences", [
      "mavenlinkProjectFilter",
      "mavenlinkStatusFilter",
      "filterRecentlyUpdated",
    ]),
  },
}
</script>
