<template>
  <v-card class="mx-auto">
    <v-card v-if="!jiraApiKey" color="grey lighten-5" class="mb-12">
      <v-card-title class="headline">Jira API Key</v-card-title>

      <v-card-subtitle>Let's configure your Jira API Key</v-card-subtitle>
      <v-card-text>
        Please navigate to the following url:
        <a
          target="_blank"
          href="https://id.atlassian.com/manage-profile/security/api-tokens"
          >https://id.atlassian.com/manage-profile/security/api-tokens</a
        >
        and create an API token, then copy and paste it into the field below.
        <v-text-field
          @keyup="valid = true"
          v-model="apiKey"
          label="Jira API Token"
          class="mt-5 mb-5"
        ></v-text-field>
        <v-btn @click="updateJiraApiKey" color="success">Save</v-btn>
      </v-card-text>
      <v-container> </v-container>
    </v-card>

    <v-data-iterator
      v-else
      :items="issues"
      :items-per-page.sync="itemsPerPage"
      :page="page"
      :search="searchTerm"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      hide-default-footer
      :custom-filter="filterIssues"
    >
      <template v-slot:header>
        <v-toolbar rounded color="toolbar" class="toolbarText--text">
          <v-text-field
            autofocus
            autocomplete="off"
            small
            dark
            height="20px"
            hide-details
            label="Search Jira Issues"
            prepend-inner-icon="mdi-magnify"
            solo-inverted
            v-model="searchTerm"
            clearable
          ></v-text-field>
          <v-autocomplete
            v-model="jiraProject"
            :items="projects"
            chips
            label="Select Project"
            item-text="name"
            item-value="key"
            solo
            hide-details
            dark
            clearable
            style="max-width: 40%"
            class="pl-2"
          >
            <template v-slot:selection="data">
              <v-chip v-bind="data.attrs" :input-value="data.selected">
                <v-avatar left>
                  <v-img :src="data.item.avatarUrls['48x48']"></v-img>
                </v-avatar>
                {{ data.item.name }}
              </v-chip>
            </template>
            <template v-slot:item="data">
              <template>
                <v-list-item-avatar>
                  <img :src="data.item.avatarUrls['48x48']" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    v-html="data.item.name"
                  ></v-list-item-title>
                  <v-list-item-subtitle
                    v-html="data.item.group"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>

          <v-spacer></v-spacer>

          <v-btn
            :loading="isFindIssuesPending"
            @click="findIssues(issuesParams)"
            class="toolbarText--text"
            icon
          >
            <v-icon> mdi-refresh </v-icon>
          </v-btn>

          <template v-slot:extension>
            <v-tabs slider-size="5" v-model="tab" grow dark>
              <v-tabs-slider color="accent"></v-tabs-slider>
              <template v-if="!searchTerm">
                <v-tab> Assigned Issues </v-tab>
                <v-tab> All Issues </v-tab>
              </template>
              <v-tab v-else>
                <template>Search</template>
              </v-tab>
            </v-tabs>
            <v-progress-linear
              :active="isFindIssuesPending"
              :indeterminate="isFindIssuesPending"
              absolute
              bottom
              color="accent"
            ></v-progress-linear>
          </template>
        </v-toolbar>
      </template>

      <template v-slot:no-data>
        <v-alert v-if="!isFindIssuesPending" class="ma-2" color="warning"
          >No items found.
          {{
            !jiraProject ? "Please select a project from the dropdown." : ""
          }}</v-alert
        >
        <v-alert v-else class="ma-2" color="light">Loading....</v-alert>
      </template>

      <template v-slot:no-results>
        <v-alert
          v-if="!isPending && !isSearchPending"
          class="ma-2"
          color="warning"
          >No matching items found.</v-alert
        >
        <v-alert v-else class="ma-2" color="light">Loading...</v-alert>
      </template>

      <template v-slot:default="props">
        <v-list>
          <Issue
            type="jira"
            :index="index"
            :key="index"
            :issue="issue"
            v-for="(issue, index) in props.items"
          />
        </v-list>
      </template>

      <template v-slot:footer>
        <v-toolbar rounded class="px-2" color="primary" dark>
          <v-row align="center" justify="center">
            <v-spacer />

            <span class="mr-4 grey--text">
              Page {{ page }} of {{ numberOfPages }}
            </span>
            <v-btn
              fab
              dark
              color="blue darken-3"
              class="mr-1"
              x-small
              @click="formerPage"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn
              fab
              x-small
              dark
              color="blue darken-3"
              class="ml-1"
              @click="nextPage"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-row>
        </v-toolbar>
      </template>
    </v-data-iterator>
  </v-card>
</template>

<script>
import Issue from "./items/Issue"
import FuzzySearch from "fuzzy-search" // Or: var FuzzySearch = require('fuzzy-search');
import { mapState } from "vuex"
import { makeFindMixin } from "feathers-vuex"
import { mapFields } from "vuex-map-fields"
export default {
  name: "JiraWidget",
  components: {
    Issue,
  },
  mixins: [
    makeFindMixin({
      service: "jira/project",
      items: "projects",
      name: "projects",
    }),
    makeFindMixin({
      service: "jira/issue",
      items: "issues",
      name: "issues",
      watch: "params",
    }),
  ],
  data() {
    return {
      itemsPerPage: 8,
      sortBy: "updated_on",
      sortDesc: true,
      apiKey: "",
    }
  },
  computed: {
    ...mapFields({
      searchTerm: "jiraSearchTerm",
      jiraProject: "userPreferences.jiraProject",
      tab: "jiraTab",
      page: "jiraPage",
    }),
    ...mapState({
      user: (state) => state.auth.user,
      jiraApiKey: (state) => state.auth.user.jiraApiKey,
    }),
    numberOfPages() {
      return Math.ceil((this.issues || []).length / 8)
    },
    projectsParams() {
      return {
        query: {},
      }
    },
    issuesParams() {
      const {
        jiraTab,
        jiraSearchTerm,
        userPreferences: { jiraProject },
      } = this.$store.state

      return {
        query: {
          ...(jiraProject ? { project: jiraProject } : {}),
          ...(jiraTab === 0 && !jiraSearchTerm ? { assigned: true } : {}),
        },
        debounce: 500,
      }
    },
    issuesFetchParams() {
      const {
        jiraTab,
        jiraSearchTerm,
        userPreferences: { jiraProject },
      } = this.$store.state

      return {
        query: {
          ...(jiraProject ? { project: jiraProject } : {}),
          ...(jiraTab === 0 && !jiraSearchTerm ? { assigned: true } : {}),
          ...(jiraSearchTerm ? { search: jiraSearchTerm } : {}),
        },
        debounce: 500,
      }
    },
  },
  methods: {
    filterIssues(items, search) {
      const searcher = new FuzzySearch(items, ["fields.summary", "key"])
      return search ? searcher.search(search) : items
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number
    },
    updateJiraApiKey() {
      this.$store
        .dispatch("users/patch", [
          this.$store.state.auth.user._id,
          {
            jiraApiKey: this.apiKey,
          },
        ])
        .then(() => {
          this.$store.dispatch("users/get", this.$store.state.auth.user._id)
          window.location.reload()
        })
    },
  },
}
</script>
