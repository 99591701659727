<template>
  <v-container fluid>
    <v-card class="mx-auto">
      <v-toolbar color="toolbar" class="toolbarText--text">
        <v-toolbar-title style="width: 100%"
          >User Preferences
          <small class="float-right"
            >(Automatically Saves)</small
          ></v-toolbar-title
        >
      </v-toolbar>
      <v-container fluid>
        <v-form
          class="mt-5"
          ref="preferenceForm"
          v-model="valid"
          lazy-validation
        >
          <h4>Interface</h4>
          <v-switch
            hint="Enable dark mode for the application."
            persistent-hint
            v-model="darkMode"
            label="Dark Mode"
          ></v-switch>
          <v-switch
            hint="Always show weekend days in the weekly summary, even if there are no time entries for the weekend days."
            persistent-hint
            v-model="showWeekend"
            label="Always Show Weekend Days"
          ></v-switch>
          <v-switch
            hint="Show the issue tracker in the dashboard. You may disable this if you do not use Jira or Redmine."
            persistent-hint
            v-model="showIssueTracker"
            label="Show Issue Tracker"
          ></v-switch>
          <v-switch
            hint="Show the 'Today Widget' in the left bottom sidebar. The 'Today Widget' shows the time entries for the current day."
            persistent-hint
            v-model="showTodayWidget"
            label="Show 'Today Widget' in Sidebar"
          ></v-switch>
        </v-form>
      </v-container>
    </v-card>
    <br />
    <v-card class="mx-auto">
      <v-toolbar color="toolbar" class="toolbarText--text">
        <v-toolbar-title>Integration Settings</v-toolbar-title>
      </v-toolbar>
      <v-container fluid>
        <v-form class="mt-5" ref="form" v-model="valid" lazy-validation>
          <v-text-field
            v-model="user.mavenlinkEmail"
            label="MavenLink Email"
            disabled
          ></v-text-field>

          <v-text-field
            @keyup="valid = true"
            v-model="redmineApiKey"
            :counter="40"
            :rules="redmineApiKeyRules"
            label="Redmine API Key"
            required
            :success-messages="redmineSuccess"
            :error-messages="redmineError"
          ></v-text-field>

          <v-text-field
            @keyup="valid = true"
            v-model="jiraApiKey"
            label="Jira API Key"
            required
          ></v-text-field>

          <v-btn
            :disabled="!valid"
            color="success"
            class="mr-4"
            @click="validate"
          >
            Save and Validate
          </v-btn>
        </v-form>
      </v-container>
    </v-card>
    <div
      v-if="
        $store.state.auth.user &&
        $store.state.auth.user.email === 'will@orases.com'
      "
    >
      <br />
      <v-card class="mx-auto">
        <v-toolbar color="toolbar" class="toolbarText--text">
          <v-toolbar-title>Admin Settings</v-toolbar-title>
        </v-toolbar>
        <v-container fluid>
          <v-form class="mt-5" ref="form" v-model="valid" lazy-validation>
            <v-text-field
              @keyup="valid = true"
              v-model="alertMessage"
              label="Alert Message"
            ></v-text-field>

            <br />
            <v-btn color="success" class="mr-4" @click="saveAlert">
              Save Alert
            </v-btn>
            <v-btn color="warning" class="mr-4" @click="clearAlert">
              Clear Alert
            </v-btn>
          </v-form>
        </v-container>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex"
import { mapFields } from "vuex-map-fields"

export default {
  computed: {
    ...mapFields("userPreferences", [
      "darkMode",
      "showWeekend",
      "showIssueTracker",
      "showTodayWidget",
    ]),
    ...mapGetters("auth", ["user"]),
  },
  data: () => ({
    alertMessage: "",
    alertId: "",
    redmineSuccess: "",
    redmineError: "",
    valid: true,
    jiraApiKey: "",
    redmineApiKey: "",
    redmineApiKeyRules: [
      (v) => !!v || "Redmine API Key is required",
      (v) => (v && v.length <= 40) || "API Key must be 40 characters",
      (v) => (v && v.length >= 40) || "API Key must be 40 characters",
    ],
    intervalsApiKey: "",
    intervalsApiKeyRules: [
      (v) => !!v || "Intervals API Key is required",
      (v) => (v && v.length <= 11) || "API Key must be 11 characters",
      (v) => (v && v.length >= 11) || "API Key must be 11 characters",
    ],
  }),
  mounted() {
    this.redmineApiKey = this.$store.state.auth.user.redmineApiKey
    this.jiraApiKey = this.$store.state.auth.user.jiraApiKey
    this.$store.dispatch("alerts/find").then((response) => {
      if (response.data.length > 0) {
        this.alertMessage = response.data[0].message
        this.alertId = response.data[0]._id
      }
    })
  },
  methods: {
    saveAlert() {
      if (this.alertId) {
        this.$store.dispatch("alerts/patch", [
          this.alertId,
          {
            message: this.alertMessage,
          },
        ])
      } else {
        this.$store
          .dispatch("alerts/create", {
            message: this.alertMessage,
          })
          .then((alert) => {
            this.alertId = alert._id
          })
      }
    },
    clearAlert() {
      this.$store.dispatch("alerts/remove", this.alertId).then(() => {
        this.alertMessage = ""
        this.alertId = ""
      })
    },
    validate() {
      let $this = this
      // TODO: Do this correctly
      if (this.$refs.form.validate()) {
        this.$store.dispatch("users/patch", [
          this.$store.state.auth.user._id,
          {
            redmineApiKey: this.redmineApiKey,
            jiraApiKey: this.jiraApiKey,
          },
        ])
        this.$store
          .dispatch("redmine/me/find", {
            query: {
              apiKey: this.redmineApiKey,
            },
          })
          .then((me) => {
            $this.redmineError = ""
            $this.redmineSuccess = `You're all set with Redmine, ${me[0].firstname}.`

            this.$store.dispatch("users/patch", [
              this.$store.state.auth.user._id,
              {
                redmineApiKey: this.redmineApiKey,
              },
            ])
          })
          .catch(() => {
            $this.redmineError = `Uh, oh. We couldn't connect to your redmine account. Check your API key and try again.`
          })

        // intervals
        this.$store
          .dispatch("intervals/me/find", {
            query: {
              apiKey: this.intervalsApiKey,
            },
          })
          .then((me) => {
            $this.intervalsError = ""
            $this.intervalsSuccess = `You're all set with Intervals, ${
              me[0].firstname
            }. Get ready to save time and be the best ${me[0].title.toLowerCase()} you can be.`

            this.$store
              .dispatch("users/patch", [
                this.$store.state.auth.user._id,
                {
                  intervalsId: me[0].id,
                  intervalsApiKey: this.intervalsApiKey,
                },
              ])
              .then(() => {
                this.$store.dispatch("auth/authenticate")
              })
          })
          .catch(() => {
            $this.intervalsError = `Uh, oh. We couldn't connect to your intervals account. Check your API key and try again.`
          })
      }
    },
  },
}
</script>
