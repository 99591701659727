<template>
  <v-card class="mx-auto">
    <v-toolbar class="toolbarText--text" color="toolbar" flat>
      <v-toolbar-title>Calendar Events</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-progress-linear
        :active="isPending"
        :indeterminate="isPending"
        absolute
        bottom
        color="accent"
      ></v-progress-linear>
      <v-spacer></v-spacer>
      <v-chip v-if="currentWeek" color="green" text-color="white">
        Week of {{ currentWeek }}
      </v-chip>
      <v-btn @click="findPrevious" class="toolbarText--text" icon>
        <v-icon>
          mdi-arrow-left
        </v-icon>
      </v-btn>

      <v-btn @click="findNext" class="toolbarText--text" icon>
        <v-icon>
          mdi-arrow-right
        </v-icon>
      </v-btn>
      <v-btn :loading="isPending" @click="reset" class="toolbarText--text" icon>
        <v-icon>
          mdi-refresh
        </v-icon>
      </v-btn>
    </v-toolbar>
    <v-container>
      <v-timeline responsive dense clipped>
        <TimelineItem
          v-for="event in futureEvents"
          :key="event.id"
          v-bind:event="event"
        ></TimelineItem>
        <v-timeline-item v-if="!currentWeek" :key="1" class="mb-6" hide-dot>
          <span>{{ currentTime }}</span>
          <v-divider></v-divider>
        </v-timeline-item>
        <TimelineItem
          :isPast="true"
          v-for="event in pastEvents"
          :key="event.id"
          v-bind:event="event"
        ></TimelineItem>
      </v-timeline>
    </v-container>
    <ConfirmationDialog ref="confirm"></ConfirmationDialog>
  </v-card>
</template>
<script>
import TimelineItem from "./calendar/TimelineItem"
import ConfirmationDialog from "./ConfirmationDialog"
import moment from "moment"
import { computed } from "@vue/composition-api"
import { useFind } from "feathers-vuex"

export default {
  name: "GoogleCalendarWidget",
  setup(props, context) {
    const { Calendar } = context.root.$FeathersVuex.api
    const { $store } = context.root

    let baseTime = new moment()
    let timeMin = new moment().subtract(5, "days").toISOString()
    let timeMax = new moment().add(1, "day").toISOString()

    $store.dispatch("updateEventsStart", timeMin)
    $store.dispatch("updateEventsEnd", timeMax)
    $store.dispatch("updateCurrentWeek", "")

    const CalendarParams = computed(() => {
      return $store.getters.calendarParams
    })

    const { items: events, isPending, find } = useFind({
      model: Calendar,
      params: CalendarParams,
    })

    const pastEvents = computed(() => {
      const { currentMoment } = $store.state
      return events.value
        .filter((event) =>
          moment(event.start.dateTime).isSameOrBefore(currentMoment)
        )
        .sort((a, b) =>
          moment(a.start.dateTime).isSameOrAfter(moment(b.start.dateTime))
            ? -1
            : 1
        )
    })

    const futureEvents = computed(() => {
      const { currentMoment } = $store.state
      return events.value
        .filter((event) =>
          moment(event.start.dateTime).isSameOrAfter(currentMoment)
        )
        .sort((a, b) =>
          moment(a.start.dateTime).isSameOrAfter(moment(b.start.dateTime))
            ? -1
            : 1
        )
    })

    const currentTime = computed(() => {
      return $store.state.currentTime
    })

    const currentWeek = computed(() => {
      return $store.state.currentWeek
    })

    setInterval(() => {
      $store.dispatch("updateCurrentTime")
      $store.dispatch("updateCurrentMoment")
    }, 1000 * 30)

    $store.dispatch("updateCurrentTime")

    const updateCurrentWeek = () => {
      $store.dispatch(
        "updateEventsStart",
        baseTime.startOf("isoWeek").toISOString()
      )
      $store.dispatch(
        "updateEventsEnd",
        baseTime.endOf("isoWeek").toISOString()
      )
      $store.dispatch(
        "updateCurrentWeek",
        baseTime.startOf("isoWeek").format("MMMM, Do")
      )

      if (
        baseTime.startOf("isoWeek").toISOString() ===
        new moment().startOf("isoWeek").toISOString()
      ) {
        $store.dispatch("updateCurrentWeek", "")
      }
    }

    const findPrevious = () => {
      baseTime = baseTime.subtract(1, "week")
      updateCurrentWeek()
    }

    const findNext = () => {
      baseTime = baseTime.add(1, "week")
      updateCurrentWeek()
    }

    const reset = () => {
      baseTime = new moment()

      $store.dispatch(
        "updateEventsStart",
        new moment().subtract(5, "days").toISOString()
      )
      $store.dispatch(
        "updateEventsEnd",
        new moment().add(1, "days").toISOString()
      )
      $store.dispatch("updateCurrentWeek", "")
    }

    return {
      currentWeek,
      events,
      pastEvents,
      futureEvents,
      isPending,
      find,
      currentTime,
      CalendarParams,
      findPrevious,
      findNext,
      reset,
      baseTime,
    }
  },
  components: {
    TimelineItem,
    ConfirmationDialog,
  },
}
</script>
