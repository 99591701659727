import Vue from "vue"
import VueRouter from "vue-router"
import Settings from "../views/Settings.vue"
import Dashboard from "../views/Dashboard.vue"
import Logs from "../views/Logs.vue"
import Users from "../views/Users.vue"
import Changelog from "../views/Changelog.vue"
import Priorities from "../views/Priorities.vue"
import TimesheetSubmissions from "../views/TimesheetSubmissions.vue"
Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      summary: true,
    },
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    meta: {
      summary: false,
    },
  },
  {
    path: "/logs",
    name: "Logs",
    component: Logs,
    meta: {
      summary: true,
    },
  },
  {
    path: "/users",
    name: "Users",
    component: Users,
    meta: {
      admin: true,
      summary: false,
    },
  },
  {
    path: "/changelog",
    name: "Changelog",
    component: Changelog,
    meta: {
      summary: false,
    },
  },
   {
    path: "/priorities",
    name: "Priorities",
    component: Priorities,
    meta: {
      summary: false,
    },
  },
   {
    path: "/timesheet-submissions",
    name: "TimesheetSubmissions",
    component: TimesheetSubmissions,
    meta: {
      summary: false,
    },
  },
]

const router = new VueRouter({
  routes,
})

export default router
