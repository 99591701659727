<template>
  <v-container fluid>
    <v-data-table
      :group-by="['date_performed']"
      :sort-by="['date_performed']"
      :group-desc="true"
      :headers="timeLogsHeaders"
      :items="parsedTimeLogs"
      :items-per-page="50"
      :hide-default-footer="false"
      :disable-pagination="false"
      search
      :loading="isPending"
      class="elevation-1"
      :footer-props="{
        'items-per-page-options': [50, 100],
      }"
    >
      <template v-slot:progress>
        <v-progress-linear
          :indeterminate="isPending"
          absolute
          bottom
          color="accent"
        ></v-progress-linear>
      </template>
      <template v-slot:top>
        <v-toolbar
          style="border-radius: 4px 4px 0px 0px"
          class="toolbarText--text"
          color="toolbar"
        >
          <v-toolbar-title>Time Logs</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            :loading="isPending"
            class="toolbarText--text"
            @click="find"
            icon
          >
            <v-icon> mdi-refresh </v-icon>
          </v-btn>
          <v-btn
            class="toolbarText--text"
            @click="$store.dispatch('time/logTime', {})"
            icon
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:[`group.header`]="{ items }">
        <td></td>
        <template v-if="$vuetify.breakpoint.smAndUp">
          <td colspan="2">
            <b>{{ items[0].date_performed }} </b>
          </td>
          <td>
            <b>{{ items.length }} time logs</b>
          </td>
          <td colspan="3">
            <b
              >{{
                items
                  .reduce((a, b) => +a + +(b.time_in_minutes / 60), 0)
                  .toFixed(2)
              }}
            </b>
          </td>
        </template>
        <template v-else>
          <td>
            <div class="d-flex">
              <b>{{ items[0].date_performed }} </b>
              <v-spacer />
              <b>{{ items.length }} time logs</b>
              <v-spacer />
              <b
                >{{
                  items
                    .reduce((a, b) => +a + +(b.time_in_minutes / 60), 0)
                    .toFixed(2)
                }}
                hours
              </b>
            </div>
          </td>
        </template>
      </template>

      <template v-slot:[`item.icon`]="{ item }">
        <div class="d-flex">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark v-bind="attrs" v-on="on" icon>
                <v-icon :color="item.synced ? 'success' : 'warning'">
                  {{ item.synced ? "mdi-sync" : "mdi-sync-alert" }}
                </v-icon>
              </v-btn>
            </template>
            <span>{{
              item.synced
                ? "Time Entry synced."
                : "Time Entry is pending sync with Mavenlink."
            }}</span>
          </v-tooltip>

          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark v-bind="attrs" v-on="on" icon>
                <v-icon
                  :color="
                    item.approved
                      ? 'error'
                      : item.active_submission_id && !item.approved
                      ? 'warning'
                      : 'success'
                  "
                >
                  {{
                    item.approved
                      ? "mdi-lock-outline"
                      : item.active_submission_id && !item.approved
                      ? "mdi-lock-alert-outline"
                      : "mdi-lock-open-outline"
                  }}
                </v-icon>
              </v-btn>
            </template>
            <span>{{
              item.approved
                ? "Time Entry is approved and locked."
                : item.active_submission_id && !item.approved
                ? "Time Entry was submitted for approval but has yet to be approved."
                : "Time Entry has not been approved yet."
            }}</span>
          </v-tooltip>
        </div>
      </template>
      <template v-slot:[`item.project`]="{ item }">
        {{ item.workspace ? item.workspace.title : "Awaiting sync..." }}
        <v-progress-linear
          v-if="!item.synced"
          buffer-value="0"
          stream
        ></v-progress-linear>
      </template>
      <template v-slot:[`item.task`]="{ item }">
        {{ item.story ? item.story.title : "" }}
      </template>
      <template v-slot:[`item.billable`]="{ item }">
        {{ item.billable ? "Yes" : "No" }}
      </template>
      <template v-slot:[`item.time_in_minutes`]="{ item }">
        {{ parseFloat(item.time_in_minutes / 60).toFixed(2) }}h
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <template v-if="!item.approved">
          <v-btn @click="deleteTimeLog(item)" icon>
            <v-icon color="red"> mdi-delete </v-icon>
          </v-btn>
          <v-btn
            class="ml-2"
            :loading="$store.state.loadingButtons.includes(item.id)"
            icon
            @click="$store.dispatch('time/editLog', item.id)"
          >
            <v-icon color="primary"> mdi-pencil </v-icon>
          </v-btn>
          <v-btn
            class="ml-2"
            icon
            :disabled="!item.synced"
            :loading="$store.state.loadingButtons.includes(`copy-${item.id}`)"
            @click="$store.dispatch('time/copyLog', item.id)"
          >
            <v-icon color="primary"> mdi-content-copy </v-icon>
          </v-btn>
        </template>
        <v-btn
          v-if="item.internalTimeLog && item.internalTimeLog.jiraIssueKey"
          class="ml-2"
          icon
          target="_blank"
          :href="`https://orases.atlassian.net/browse/${item.internalTimeLog.jiraIssueKey}?focusedWorklogId=${item.internalTimeLog.jiraLogId}`"
        >
          <v-icon color="primary"> mdi-jira </v-icon>
        </v-btn>

        <v-btn
          v-if="
            item.internalTimeLog &&
            item.internalTimeLog.issueId &&
            !item.internalTimeLog.jiraIssueId
          "
          class="ml-2"
          icon
          target="_blank"
          :href="`https://redmine.orases.com/issues/${item.internalTimeLog.issueId}`"
        >
          <img
            width="25"
            src="https://katalon-store.s3.amazonaws.com/nbdn34vruye216te-redmine-logo.png"
          />
        </v-btn>
        <v-btn
          v-if="item.internalTimeLog && item.internalTimeLog.eventId"
          class="ml-2"
          icon
          target="_blank"
          :href="getCalendarLink(item)"
        >
          <v-icon> mdi-calendar-blank </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <ConfirmationDialog ref="confirm"></ConfirmationDialog>
  </v-container>
</template>

<script>
import ConfirmationDialog from "../components/ConfirmationDialog"
import { computed } from "@vue/composition-api"
import { useFind } from "feathers-vuex"
import moment from "moment"
export default {
  name: "Logs",
  mounted() {
    this.find()
  },
  setup(props, context) {
    const { TimeEntry, Story } = context.root.$FeathersVuex.api
    const { refs: $refs } = context
    let { $store } = context.root

    const TimeEntryParams = computed(() => {
      const { mavenlinkId } = $store.state.auth.user
      return {
        query: {
          $limit: 200,
          $sort: {
            date_performed: -1,
          },
          ...(mavenlinkId ? { user_id: `${mavenlinkId}` } : {}),
        },
      }
    })

    const StoryParams = computed(() => {
      return {
        query: {
          assignee: true,
        },
      }
    })

    const {
      items: timeLogs,
      isPending,
      find,
    } = useFind({
      model: TimeEntry,
      params: TimeEntryParams,
    })

    const { items: tasks } = useFind({
      model: Story,
      params: StoryParams,
    })

    $store.dispatch("mavenlink/time-entry/find", TimeEntryParams.value)

    const getTaskField = (taskId, field) => {
      let task = tasks.value.find((task) => taskId == task.id)
      if (task) {
        return task[field]
      } else {
        return field
      }
    }

    const deleteTimeLog = (log) => {
      $refs.confirm
        .open(
          "Confirmation",
          `Are you sure you want to delete the time log <b>${
            log.notes || "No Description"
          }</b>?`,
          { color: "red" }
        )
        .then((confirm) => {
          if (confirm) {
            $store
              .dispatch("mavenlink/time-entry/remove", log.id)
              .then(() => {
                $store.dispatch("showToastSuccess", "Time log deleted.")
              })
              .catch((err) => {
                $store.dispatch(
                  "showToastError",
                  `Error deleting time log. ${err.error}`
                )
              })
          }
        })
    }

    const timeLogsHeaders = [
      {
        value: "icon",
      },
      {
        text: "Project",
        value: "project",
      },
      {
        text: "Task",
        value: "task",
      },
      {
        text: "Description",
        value: "notes",
        align: "start",
      },
      {
        text: "Date",
        value: "date_performed",
      },
      {
        text: "Time",
        value: "time_in_minutes",
      },
      {
        text: "Billable",
        value: "billable",
      },
      {
        text: "Actions",
        value: "actions",
        width: "15%",
      },
    ]

    const parsedTimeLogs = computed(() => {
      let logs = timeLogs.value.map((timeLog) => {
        let task = tasks.value.find((task) => timeLog.story_id == task.id)
        if (task) {
          timeLog.workspace = task.workspace
          timeLog.story = task
        }
        return timeLog
      })

      return logs
    })

    return {
      parsedTimeLogs,
      timeLogs,
      tasks,
      deleteTimeLog,
      getTaskField,
      isPending,
      find,
      timeLogsHeaders,
    }
  },
  components: {
    ConfirmationDialog,
  },
  data: () => ({
    type: "month",
    types: ["month", "week", "day", "4day"],
    mode: "stack",
    modes: ["stack", "column"],
    weekday: [0, 1, 2, 3, 4, 5, 6],
    weekdays: [
      { text: "Sun - Sat", value: [0, 1, 2, 3, 4, 5, 6] },
      { text: "Mon - Sun", value: [1, 2, 3, 4, 5, 6, 0] },
      { text: "Mon - Fri", value: [1, 2, 3, 4, 5] },
      { text: "Mon, Wed, Fri", value: [1, 3, 5] },
    ],
    value: "",
    events: [],
    colors: [
      "blue",
      "indigo",
      "deep-purple",
      "cyan",
      "green",
      "orange",
      "grey darken-1",
    ],
    names: [
      "Meeting",
      "Holiday",
      "PTO",
      "Travel",
      "Event",
      "Birthday",
      "Conference",
      "Party",
    ],
  }),
  methods: {
    getSyncTime(item) {
      return moment(item.created_at).toNow()
    },
    getCalendarLink(item) {
      if (!item.internalTimeLog.eventId) return false
      return `https://calendar.google.com/calendar/u/0/event?eid=${window
        .btoa(
          item.internalTimeLog.eventId + " " + this.$store.state.auth.user.email
        )
        .replace("=", "")}`
    },
    getEvents() {
      const newEvents = this.timeLogs.map((log) => {
        return {
          name: log.description,
          start: moment(log.date).toDate(),
          end: moment(log.date).add(log.time, "minutes").toDate(),
          color: "blue",
          timed: false,
        }
      })

      this.events = newEvents
    },
    getEventColor(event) {
      return event.color
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    },
  },
}
</script>
