import stringSimilarity from "string-similarity"
import moment from "moment"
import { getField, updateField } from "vuex-map-fields"
const getRoundedTimeFromString = (time) => {
  return getTimeFromString(
    Math.ceil(parseFloat(getTimeFromString(time).time) / 0.25) * 0.25
  )
}

const getTimeFromString = (time) => {
  time = "" + time ?? ""
  let hours = time,
    mins = 0

  let hourMinMatch = time.match(/(\d.*):(\d{1,2})/)
  let hourDecMatch = time.match(/(\d+)\.(\d*)+$/)
  let hourMatch = time.match(/^\d*$/)
  let minMatch = time.match(/^\.\d*$/)
  let total = hours

  if (hourMatch) {
    hours = parseInt(hourMatch[0])
    mins = 0
  }

  if (minMatch) {
    hours = 0
    mins = parseFloat(minMatch[0]) * 60
  }

  if (hourMinMatch) {
    hours = parseInt(hourMinMatch[1])
    mins = parseInt(hourMinMatch[2])
    total = hours + mins / 60
  }

  if (hourDecMatch) {
    hours = parseInt(hourDecMatch[1])
    mins = parseInt(parseFloat("0." + hourDecMatch[2]) * 60)
    total = time
  }

  total = parseFloat(total).toFixed(2)

  return {
    hours: hours,
    mins: mins,
    time: total ? total : `${hours}.${parseInt((mins * 100) / 60)}`,
    string: `${hours} hour${hours > 1 ? "s" : ""} ${
      mins > 0 ? " and " + mins + " minutes" : ""
    }`,
  }
}

const time = {
  namespaced: true,
  state: {
    timeDialog: {
      show: false,
      task: null,
      time: null,
      timeMin: null,
      type: null,
      date: null,
      billable: true,
      issue: null,
      updateSpent: true,
    },
  },
  mutations: {
    hideTimeDialog(state, payload) {
      state.timeDialog.show = payload
    },
    updateTimeDialog(state, payload) {
      state.timeDialog = { ...payload }
    },
    updateMatchReasons(state, payload) {
      state.timeDialog.matchReasons = payload
    },
    updateField,
  },
  actions: {
    logTimeToMavenlink: ({
      state,
      dispatch,
      commit,
      rootGetters,
      rootState,
    }) => {
      let calendarParams = {
        query: {
          start: {
            dateTime: {
              $lt: rootState.eventsEnd,
              $gt: rootState.eventsStart,
            },
          },
        },
      }

      if (state.timeDialog.modify) {
        const story = rootGetters["mavenlink/story/get"](
          state.timeDialog.task
        )
        return dispatch(
          "mavenlink/time-entry/patch",
          [
            state.timeDialog.modify,
            {
              notes: state.timeDialog.description,
              story_id: state.timeDialog.task,
              date_performed: state.timeDialog.date,
              time: getRoundedTimeFromString(state.timeDialog.time).time,
              billable: state.timeDialog.billable,
              ...(story ? {
                workspace_id: story.workspace_id,
                story
              } : {})
            },
          ],
          { root: true }
        )
          .then(() => {
            dispatch("showToastSuccess", "Time log updated!", { root: true })
            commit("hideTimeDialog", false)
            dispatch("google/calendar/find", calendarParams, { root: true })
          })
          .catch((err) => {
            if (err.message) {
              commit("SET_ERROR_MESSAGE", err.message, {
                root: true,
              })
            }
          })
      } else {
        // Get projectid
        let storyObject = rootGetters["mavenlink/story/get"](
          state.timeDialog.task
        )

        const timeEntryPayload = {
          date_performed: state.timeDialog.date,
          notes: state.timeDialog.description,
          time: getRoundedTimeFromString(state.timeDialog.time).time,
          eventId: state.timeDialog.event ? state.timeDialog.event : false,
          issue: state.timeDialog.issue ? state.timeDialog.issue : false,
          ...(storyObject !== undefined && storyObject !== null
            ? {
                story_id: storyObject.id,
                workspace_id: storyObject.workspace_id,
                story: storyObject,
              }
            : {}),
        }

        return dispatch("mavenlink/time-entry/create", timeEntryPayload, {
          root: true,
        })
          .then(() => {
            dispatch("showToastSuccess", "Time log created!", { root: true })
            if (state.timeDialog.event) {
              dispatch("google/calendar/find", calendarParams, { root: true })
              commit("hideTimeDialog", false)
            } else {
              commit("hideTimeDialog", false)
            }
          })
          .catch((err) => {
            if (err.message) {
              commit("SET_ERROR_MESSAGE", err.message, {
                root: true,
              })
            } else {
              commit(
                "SET_ERROR_MESSAGE",
                "An unknown error occured while logging your time entry.",
                {
                  root: true,
                }
              )
            }
          })
      }
    },
    async logTime({ dispatch, commit, rootState }, payload) {
      // Get Redmine User unless issue tracker disabled
      const redmineUser = rootState.auth.user.disableIssueTracker || !rootState.auth.user.redmineApiKey ? [] : await dispatch("redmine/me/find", false, {
        root: true,
      })

      // This is a workaround to get all MavenLink tasks to load when needed
      // we don't need to load all tasks when logging via Jira or Directly
      if ((!payload.issue && !payload.directLog) || (payload.issue && !payload.issue.fields)) {
        dispatch("updateMavenlinkTab", 1, { root: true })
      }

      let timeDialog = Object.assign(
        {
          billable: true,
          suggestedTime: 0,
          redmineUserId: redmineUser.length ? redmineUser[0].id : false,
        },
        payload
      )

      // TODO: You're making a mess. Get your shit together.
      if (payload.issue) {
        if (payload.issue.total_estimated_hours) {
          let suggestedTime =
            payload.issue.total_estimated_hours -
            (payload.issue.total_spent_hours || 0)

          if (suggestedTime > 0) {
            timeDialog.suggestedTime = suggestedTime
          }
        }

        if (payload.issue.fields && payload.issue.fields.timeoriginalestimate) {
          timeDialog.suggestedTime =
            (payload.issue.fields.timeoriginalestimate -
              payload.issue.fields.progress.progress) /
            3600
        }

        const { data } = await dispatch(
          "time-entries/find",
          {
            query: {
              $or: [
                { issueId: parseInt(payload.issue.id) },
                { jiraIssueId: parseInt(payload.issue.id) },
              ],
              $sort: {
                createdAt: -1,
              },
            },
          },
          { root: true }
        )

        if (data.length) {
          let previousLog = data[0]

          timeDialog.task = previousLog.taskId
        }
      }

      if (payload && payload.loadingId) {
        dispatch("unloadButton", payload.loadingId, { root: true })
      }

      // Show the dialog
      timeDialog.show = true

      // Clear error
      commit("SET_ERROR_MESSAGE", "", { root: true })

      if (!timeDialog.date) {
        timeDialog.date = moment().format("YYYY-MM-DD")
      } else {
        timeDialog.date = moment(timeDialog.date).format("YYYY-MM-DD")
      }

      if (timeDialog.taskMatchString && !timeDialog.task && 1 === 2) {
        // Loading button
        if (payload && payload.loadingId) {
          dispatch("loadButton", payload.loadingId, { root: true })
        }

        // Get the current tasks
        let tasks = payload.tasks || this.getters["mavenlink/story/list"]
        let matchReasons = []

        // Halt button loading
        if (payload.loadingId) {
          dispatch("unloadButton", payload.loadingId, { root: true })
        }

        // Get words in project
        let projectWords = (
          timeDialog.taskMatchString +
          " " +
          timeDialog.description
        )
          .replace(/[^\w\s]/gi, "")
          .split(" ")
          .filter((word) => word != "")

        // Add project acronym
        projectWords.push(timeDialog.taskMatchString.match(/\b(\w)/g).join(""))

        if (timeDialog.isInternal) {
          matchReasons.push("Internal company-wide event detected.")
          projectWords.push("Internal")
          projectWords.push("Orases")
        }

        if (timeDialog.isInternalTeam) {
          matchReasons.push("Internal team event detected.")
          projectWords.push("Internal")
          projectWords.push("Orases")
        }

        // Map clients
        let clients = tasks
          .map((task) => {
            const clientName =
              task.workspace && task.workspace.client_name
                ? task.workspace.client_name.includes(" - ")
                  ? task.workspace.client_name.split(" - ")[1]
                  : task.workspace.client_name
                : ""

            return {
              id: task.workspace_id,
              fullName: clientName,
              name: clientName.replace(/[^\w\s!?]/g, ""),
            }
          })
          .filter((c) => c.name)

        // Add acronyms
        clients = clients.concat(
          clients.map((client) => {
            client.name = client.name.match(/\b(\w)/g).join("")
            return client
          })
        )

        // Filter tasks based on matches
        tasks = tasks
          .map((task) => {
            let words = task.title.replace(/[^\w\s]/gi, "").split(" ")
            task.matchedWords = words.filter((x) => projectWords.includes(x))
            task.matches = task.matchedWords.length
            return task
          })
          .filter((task) => task.matches > 0)

        // Get client matches
        let clientMatches = stringSimilarity.findBestMatch(
          projectWords.join(" "),
          clients.map((client) => client.name)
        )

        // Process client match
        if (clientMatches.bestMatch) {
          let bestMatch = clientMatches.bestMatch
          if (bestMatch.rating > 0) {
            let clientMatch = clients.find(
              (client) => client.name === bestMatch.target
            )
            if (clientMatch) {
              matchReasons.push(`Client name matched: ${bestMatch.target}`)
              tasks = tasks.filter(
                (task) =>
                  parseInt(task.workspace_id) === parseInt(clientMatch.id)
              )
            }
          }
        }

        // Sort by matches
        tasks = tasks.sort((a, b) => (a.matches > b.matches ? -1 : 1))

        // Sort by client, Orases should be last case scenerio
        tasks = tasks.sort((a) => (a.client === "Orases" ? 1 : -1))

        // Check if we got a match
        if (tasks.length) {
          let task = tasks[0]
          timeDialog.task = `${task.id}`

          matchReasons.push(
            `Task words matched: ${task.matchedWords.join(", ")}`
          )
          timeDialog.matchReasons = matchReasons
        }

        commit("updateTimeDialog", timeDialog)
      } else {
        commit("updateTimeDialog", timeDialog)
      }
    },
    editLog({ dispatch, commit }, logId) {
      dispatch("loadButton", logId, { root: true })
      commit("SET_ERROR_MESSAGE", "", { root: true })

      if (logId) {
        dispatch("mavenlink/time-entry/get", logId, { root: true }).then(
          async (timeLog) => {
            let timeDialog = {
              show: true,
              description: timeLog.notes,
              task: parseInt(timeLog.story_id),
              story: parseInt(timeLog.story_id),
              time: timeLog.time || timeLog.time_in_minutes / 60,
              date: timeLog.date_performed,
              modify: timeLog.id,
              billable: timeLog.billable,
            }

            // Load the story if available
            if (timeLog.story_id) {
              await dispatch("mavenlink/story/get", timeLog.story_id, {
                root: true,
              }).then((story) => {

                timeDialog.originalTask = story

                // If the story has a jira issue key, load the issue
                if (story.jira_issue_key) {
                  return dispatch("jira/issue/get", story.jira_issue_key, {
                    root: true,
                  }).then((issue) => {
                    // If the issue exists, update the time dialog
                    if (issue) {
                      timeDialog.issue = issue
                    }

                    return
                  })
                }
                return
              })
            }

            if (timeLog.jira_issue_key) {
              await dispatch("jira/issue/get", timeLog.jira_issue_key, {
                root: true,
              }).then((issue) => {
                if (issue) {
                  timeDialog.issue = issue
                }

                return
              })
            }

            dispatch("unloadButton", logId, { root: true })

            commit("updateTimeDialog", timeDialog)
          }
        )
      }
    },
    async copyLog({ dispatch, commit }, logId) {
      dispatch("loadButton", `copy-${logId}`, { root: true })
      commit("SET_ERROR_MESSAGE", "", { root: true })

      if (logId) {
        dispatch("mavenlink/time-entry/get", logId, { root: true }).then(
          async (timeLog) => {
            let timeDialog = {
              show: true,
              description: timeLog.notes,
              task: !timeLog.synced ? 0 : parseInt(timeLog.story_id),
              time: parseFloat(timeLog.time_in_minutes / 60).toFixed(2),
              date: timeLog.date_performed,
              billable: timeLog.billable === "t",
            }

            if (timeLog.jira_issue_key) {
              await dispatch("jira/issue/get", timeLog.jira_issue_key, {
                root: true,
              }).then((issue) => {
                if (issue) {
                  timeDialog.issue = issue
                }

                return
              })
            }

            if (timeLog.story_id) {
              await dispatch("mavenlink/story/get", timeLog.story_id, {
                root: true,
              }).then((story) => {
                // If the story has a jira issue key, load the issue
                if (story.jira_issue_key) {
                  return dispatch("jira/issue/get", story.jira_issue_key, {
                    root: true,
                  }).then((issue) => {
                    // If the issue exists, update the time dialog
                    if (issue) {
                      timeDialog.issue = issue
                    }

                    return
                  })
                }
                return
              })
            }

            const matchString = timeLog.notes || timeLog.description || ""

            let issueId = (matchString.match(/\d{5}/g) || []).map(Number)

            if (issueId.length) {
              dispatch("redmine/issue/get", issueId[0], { root: true }).then(
                (issue) => {
                  timeDialog.issue = issue
                  commit("updateTimeDialog", timeDialog)
                }
              )
            } else {
              commit("updateTimeDialog", timeDialog)
            }

            dispatch("unloadButton", `copy-${logId}`, { root: true })
          }
        )
      }
    },
  },
  getters: {
    hasSpentHoursField: (state) => {
      let hasField = false
      if (state.timeDialog.issue) {
        let issue = state.timeDialog.issue
        if ("spent_hours" in issue) {
          hasField = true
        }
        if (issue.custom_fields) {
          let actualTimeField = issue.custom_fields.find(
            (field) => field.name === "Actual Time"
          )
          if (actualTimeField) {
            hasField = true
          }
        }
      }
      return hasField
    },
    spentHours: (state) => {
      let spentHours = false

      if (state.timeDialog.issue && state.timeDialog.issue.key) {
        spentHours += state.timeDialog.issue.fields.progress.progress / 3600
      }

      if (state.timeDialog.issue) {
        let issue = state.timeDialog.issue
        if ("spent_hours" in issue) {
          spentHours = parseFloat(issue.spent_hours ? issue.spent_hours : 0)
        }
        if (issue.custom_fields) {
          let actualTimeField = issue.custom_fields.find(
            (field) => field.name === "Actual Time"
          )
          if (actualTimeField) {
            spentHours = parseFloat(
              actualTimeField.value !== "" && actualTimeField.value
                ? actualTimeField.value
                : 0
            )
          }
        }

        if (
          spentHours !== false &&
          state.timeDialog.time &&
          getTimeFromString(state.timeDialog.time).time > 0
        ) {
          spentHours += parseFloat(
            getRoundedTimeFromString(state.timeDialog.time).time
          )
        }
      }
      return spentHours
    },
    spentPercentage: (state, getters) => {
      if (!state.timeDialog.issue) {
        return 0
      }

      let totalHours =
        state.timeDialog.issue.estimated_hours ||
        (state.timeDialog.issue.fields &&
        state.timeDialog.issue.fields.timeoriginalestimate
          ? state.timeDialog.issue.fields.timeoriginalestimate / 3600
          : 0) ||
        0
      let spentHours = getters.spentHours
      let spentPercentage = (spentHours / totalHours) * 100

      return spentPercentage
    },
    timeHint: (state) => {
      if (state.timeDialog.time) {
        let formattedTime = getTimeFromString(state.timeDialog.time)
        let roundedFormattedTime = getRoundedTimeFromString(
          state.timeDialog.time
        )

        return formattedTime.string
          ? `${
              formattedTime.string !== roundedFormattedTime.string
                ? "Rounding to "
                : ""
            }${roundedFormattedTime.string}`
          : ""
      }
      return ""
    },
    getField,
  },
}

export default time
