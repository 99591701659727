<template>
  <v-row justify="center">
    <v-dialog v-model="showDialog" scrollable max-width="800px">
      <TimeForm />
    </v-dialog>
  </v-row>
</template>

<script>
import { createHelpers } from 'vuex-map-fields'
import TimeForm from '@/components/form/TimeForm.vue'

const { mapFields: scopedMapFields } = createHelpers({
  getterType: 'time/getField',
  mutationType: 'time/updateField'
})

export default {
  name: 'TimeDialog',

  components: { TimeForm },
  computed: {
    ...scopedMapFields({
      dialog: 'timeDialog',
      showDialog: 'timeDialog.show'
    })
  }
}
</script>
