<template>
  <v-card class="mx-auto mavenlink-widget">
    <v-data-iterator
      :items="filteredTasks"
      :items-per-page.sync="itemsPerPage"
      :page="page"
      :search="searchTerm"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      hide-default-footer
      :custom-filter="filterTasks"
    >
      <template v-slot:header>
        <v-toolbar rounded color="toolbar" class="toolbarText--text">
          <v-text-field
            autocomplete="off"
            small
            dark
            height="20px"
            hide-details
            label="Search Mavenlink"
            prepend-inner-icon="mdi-magnify"
            solo-inverted
            v-model="searchTerm"
            clearable
          ></v-text-field>
          <v-btn
            @click="toggleFilter"
            :class="mavenlink.showFilter ? 'success--text' : 'white--text'"
            icon
          >
            <v-icon> mdi-filter </v-icon>
          </v-btn>
          <v-btn
            :loading="isPending"
            @click="find(IssueParams)"
            class="toolbarText--text"
            icon
          >
            <v-icon> mdi-refresh </v-icon>
          </v-btn>

          <template v-slot:extension>
            <v-tabs slider-size="5" v-model="tab" grow dark>
              <v-tabs-slider color="accent"></v-tabs-slider>
              <template v-if="!searchTerm">
                <v-tab> Assigned Tasks </v-tab>
                <v-tab> All Tasks </v-tab>
              </template>
              <v-tab v-else>
                <template v-if="isPending">Searching...</template>

                <template v-else>Search</template>
              </v-tab>
            </v-tabs>
            <v-progress-linear
              :active="isPending"
              :indeterminate="isPending"
              absolute
              bottom
              color="accent"
            ></v-progress-linear>
          </template>
        </v-toolbar>
        <v-card
          max-height="100"
          v-if="mavenlink.showFilter"
          flat
          tile
          :rounded="false"
          color="toolbar"
        >
          <v-container class="d-flex flex-column">
            <Filters
              :workspaces="workspaces"
              :workspace-groups="workspace_groups"
            />
          </v-container>
        </v-card>
      </template>

      <template v-slot:no-data>
        <v-alert
          v-if="!isPending && tasksHaveLoaded"
          class="ma-2"
          color="warning"
          >No items found.</v-alert
        >
        <v-alert v-else class="ma-2" color="light">Loading....</v-alert>
      </template>

      <template v-slot:no-results>
        <v-alert v-if="!isPending" class="ma-2" color="warning"
          >No matching items found.</v-alert
        >
        <v-alert v-else class="ma-2" color="light">Loading...</v-alert>
      </template>

      <template v-slot:default="props">
        <v-list>
          <Story
            :index="index"
            :key="index"
            :story="task"
            v-for="(task, index) in props.items"
          />
        </v-list>
      </template>

      <template v-slot:footer>
        <v-toolbar rounded class="px-2" color="primary" dark>
          <v-row align="center" justify="center">
            <v-spacer />

            <span class="mr-4 grey--text">
              Page {{ page }} of {{ numberOfPages }}
            </span>
            <v-btn
              fab
              dark
              color="blue darken-3"
              class="mr-1"
              x-small
              @click="formerPage"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn
              fab
              x-small
              dark
              color="blue darken-3"
              class="ml-1"
              @click="nextPage"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-row>
        </v-toolbar>
      </template>
    </v-data-iterator>
  </v-card>
</template>

<script>
import { computed } from "@vue/composition-api"
import { useFind } from "feathers-vuex"
import Filters from "./filters/Mavenlink"
import Story from "./items/Story"
import FuzzySearch from "fuzzy-search" // Or: var FuzzySearch = require('fuzzy-search');
import { mapFields } from "vuex-map-fields"
export default {
  name: "MavenlinksWidget",
  components: {
    Filters,
    Story,
  },
  computed: {
    ...mapFields("userPreferences", ["mavenlink", "mavenlinkProjectFilter"]),
  },
  data() {
    return {
      itemsPerPage: 8,
      sortBy: "updated_on",
      sortDesc: true,
    }
  },
  methods: {
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number
    },
    toggleFilter() {
      this.mavenlink.showFilter = !this.mavenlink.showFilter
    },
  },
  setup(props, context) {
    const { Story, WorkspaceGroup, Workspace } = context.root.$FeathersVuex.api
    const { $store } = context.root

    const page = computed({
      get: () => $store.state.mavenlinkPage,
      set: (val) => {
        $store.dispatch("updateMavenlinkPage", val)
      },
    })

    const searchTerm = computed({
      get: () => $store.state.mavenlinkSearchTerm,
      set: (val) => {
        $store.dispatch("updateMavenlinkSearchTerm", val)
      },
    })

    const tab = computed({
      get: () => $store.state.mavenlinkTab,
      set: (val) => {
        $store.dispatch("updateMavenlinkTab", val)
      },
    })

    const allowQuery = computed(() => {
      let { mavenlinkId } = $store.state
      return $store.state.auth.user.mavenlinkAccessToken &&
        mavenlinkId &&
        mavenlinkId !== ""
        ? true
        : false
    })

    let tabs = ["Tasks", "Recently Updated"]

    const WorkspaceGroupParams = computed(() => {
      return {
        query: {},
        debounce: 250,
      }
    })

    const WorkspaceParams = computed(() => {
      return {
        query: {},
        debounce: 250,
      }
    })

    const { items: workspaces } = useFind({
      model: Workspace,
      params: WorkspaceParams,
    })

    const { items: workspace_groups } = useFind({
      model: WorkspaceGroup,
      params: WorkspaceGroupParams,
    })

    const IssueParams = computed(() => {
      let {
        mavenlinkProjectFilter,
        mavenlinkStatusFilter,
        query = {
          ...(tab.value === 0 && !searchTerm.value
            ? { assigned_to_current_user: true }
            : {}),
        },
      } = $store.state.userPreferences

      if (mavenlinkProjectFilter.length) {
        query.workspace_id = {
          $in: mavenlinkProjectFilter,
        }
      }

      if (mavenlinkStatusFilter.length) {
        query.workspace_id = {
          $in: mavenlinkStatusFilter,
        }
      }

      return {
        query: query,
        debounce: 250,
      }
    })

    const {
      items: tasks,
      isPending,
      find: find,
      haveLoaded: tasksHaveLoaded,
    } = useFind({
      model: Story,
      params: IssueParams,
      qid: "filteredTasksMaven",
    })

    const numberOfPages = computed(() => {
      return Math.ceil(filteredTasks.value.length / 8)
    })

    const filteredTasks = computed(() => {
      let { mavenlinkSearchTerm } = $store.state

      let mavenlinkTasks = tasks.value

      const searcher = new FuzzySearch(mavenlinkTasks, ["title"])

      let shouldSearch =
        (mavenlinkSearchTerm &&
          mavenlinkSearchTerm.length &&
          mavenlinkSearchTerm !== null) ||
        false

      return shouldSearch
        ? searcher.search(mavenlinkSearchTerm)
        : mavenlinkTasks
    })

    const filterTasks = (items, search) => {
      const searcher = new FuzzySearch(items, ["title", "id"])
      return search ? searcher.search(search) : items
    }

    return {
      filteredTasks,
      numberOfPages,
      items: tasks,
      searchTerm,
      tab,
      tabs,
      tasks,
      isPending,
      find,
      page,
      IssueParams,
      filterTasks,
      tasksHaveLoaded,
      allowQuery,
      workspaces,
      workspace_groups,
    }
  },
}
</script>
