<template>
  <v-container fluid>
    <v-data-table
      :sort-by="['time.total', 'deactivated']"
      :sort-desc="true"
      :headers="usersHeaders"
      :items="users"
      :items-per-page="50"
      :hide-default-footer="false"
      :disable-pagination="false"
      search
      :loading="isFindUsersPending"
      class="elevation-1"
      :footer-props="{
        'items-per-page-options': [50, 100],
      }"
    >
      <template v-slot:progress>
        <v-progress-linear
          :indeterminate="isFindUsersPending"
          absolute
          bottom
          color="accent"
        ></v-progress-linear>
      </template>
      <template v-slot:top>
        <v-toolbar
          style="border-radius: 4px 4px 0px 0px"
          class="toolbarText--text"
          color="toolbar"
        >
          <v-toolbar-title>Users</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            :loading="isFindUsersPending"
            class="toolbarText--text"
            @click="findUsers(usersParams)"
            icon
          >
            <v-icon> mdi-refresh </v-icon>
          </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:[`group.header`]="{ items }">
        <template v-if="$vuetify.breakpoint.smAndUp">
          <td colspan="4">
            <b>{{ items[0].date }} </b>
          </td>
          <td>
            <b>{{ items.length }} time logs</b>
          </td>
          <td colspan="3">
            <b>{{ items.reduce((a, b) => +a + +b.time, 0).toFixed(2) }} </b>
          </td>
        </template>
        <template v-else>
          <td>
            <div class="d-flex">
              <b>{{ items[0].date }} </b>
              <v-spacer />
              <b>{{ items.length }} time logs</b>
              <v-spacer />
              <b
                >{{ items.reduce((a, b) => +a + +b.time, 0).toFixed(2) }} hours
              </b>
            </div>
          </td>
        </template>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn class="ml-2" icon @click="edit(item)">
          <v-icon color="primary"> mdi-pencil </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-dialog v-model="showDialog" scrollable max-width="800px">
      <v-card>
        <v-toolbar color="primary" dark :rounded="false">
          <v-toolbar-title>Edit User</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid">
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="user.firstName"
                    label="First Name"
                    required
                    autocomplete="off"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="user.lastName"
                    label="Last Name"
                    required
                    autocomplete="off"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="user.profilePicture"
                    label="Avatar URL"
                    required
                    autocomplete="off"
                  >
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-checkbox
                    v-model="user.admin"
                    :value="true"
                    label="Admin"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" md="6">
                  <v-checkbox
                    v-model="user.deactivated"
                    :value="true"
                    label="Deactivated"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <v-divider></v-divider>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mb-4"
            color="warning darken-1"
            @click="
              project = false;
              showDialog = false;
            "
            >Cancel</v-btn
          >
          <v-btn class="mb-4 mr-3" @click="save" color="success darken-1"
            >Save User</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { makeFindMixin } from "feathers-vuex"

export default {
  data() {
    return {
      user: false,
      showDialog: false,
      valid: false,
      usersHeaders: [
        {
          text: "First Name",
          value: "firstName",
        },
        {
          text: "Last Name",
          value: "lastName",
        },
        {
          value: "actions",
        },
      ],
    }
  },
  mixins: [
    makeFindMixin({
      service: "users",
    }),
  ],
  computed: {
    usersParams() {
      return { query: { $limit: 50 } }
    },
  },
  methods: {
    edit(user) {
      this.user = Object.assign({}, user)
      this.showDialog = true
    },
    save() {
      const patchData = { ...this.user }

      this.$store.dispatch("users/patch", [this.user._id, patchData])
      this.showDialog = false
      this.user = false
    },
  },
}
</script>
