<template>
  <v-card class="mx-auto">
    <v-data-iterator
      :items="filteredIssues"
      :items-per-page.sync="itemsPerPage"
      :page="page"
      :search="searchTerm"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      hide-default-footer
      :custom-filter="filterIssues"
    >
      <template v-slot:header>
        <v-toolbar rounded color="toolbar" class="toolbarText--text">
          <v-text-field
            autofocus
            autocomplete="off"
            small
            dark
            height="20px"
            hide-details
            label="Search Global Redmines"
            prepend-inner-icon="mdi-magnify"
            solo-inverted
            v-model="searchTerm"
            clearable
          ></v-text-field>

          <v-spacer></v-spacer>

          <v-btn
            :loading="isPending || isSearchPending"
            v-if="tab === 0"
            @click="find(IssueParams)"
            class="toolbarText--text"
            icon
          >
            <v-icon>
              mdi-refresh
            </v-icon>
          </v-btn>
          <v-btn
            :loading="isRecentlyUpdatedPending"
            v-if="tab === 1"
            @click="findRecentlyUpdated(RecentlyUpdatedIssueParams)"
            class="toolbarText--text"
            icon
          >
            <v-icon>
              mdi-refresh
            </v-icon>
          </v-btn>
          <Filters :statuses="statuses" :projects="projects" />
          <template v-slot:extension>
            <v-tabs slider-size="5" v-model="tab" grow dark>
              <v-tabs-slider color="accent"></v-tabs-slider>
              <template v-if="!searchTerm">
                <v-tab>
                  Assigned Issues
                </v-tab>
                <v-tab>
                  <v-badge :content="recentlyUpdated.length" color="warning">
                    Recently Updated Issues
                  </v-badge>
                </v-tab>
              </template>
              <v-tab v-else>
                <template v-if="isPending">Searching...</template>
                <template v-else-if="isSearchPending"
                  >Searching global redmines...</template
                >
                <template v-else>Search</template>
              </v-tab>
            </v-tabs>
            <v-progress-linear
              :active="isPending"
              :indeterminate="isPending"
              absolute
              bottom
              color="accent"
            ></v-progress-linear>
          </template>
        </v-toolbar>
      </template>

      <template v-slot:no-data>
        <v-alert
          v-if="!isPending && !isSearchPending && issuesHaveLoaded"
          class="ma-2"
          color="warning"
          >No items found.</v-alert
        >
        <v-alert v-else class="ma-2" color="light">Loading....</v-alert>
      </template>

      <template v-slot:no-results>
        <v-alert
          v-if="!isPending && !isSearchPending"
          class="ma-2"
          color="warning"
          >No matching items found.</v-alert
        >
        <v-alert v-else class="ma-2" color="light">Loading...</v-alert>
      </template>

      <template v-slot:default="props">
        <v-list>
          <Issue
            :index="index"
            :key="index"
            :issue="issue"
            v-for="(issue, index) in props.items"
          />
        </v-list>
      </template>

      <template v-slot:footer>
        <v-toolbar rounded class="px-2" color="primary" dark>
          <v-row align="center" justify="center">
            <v-spacer />

            <span
              class="mr-4
            grey--text"
            >
              Page {{ page }} of {{ numberOfPages }}
            </span>
            <v-btn
              fab
              dark
              color="blue darken-3"
              class="mr-1"
              x-small
              @click="formerPage"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn
              fab
              x-small
              dark
              color="blue darken-3"
              class="ml-1"
              @click="nextPage"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-row>
        </v-toolbar>
      </template>
    </v-data-iterator>
  </v-card>
</template>

<script>
import { computed, watch } from '@vue/composition-api'
import { useFind } from 'feathers-vuex'
import Filters from './filters/Redmine'
import Issue from './items/Issue'
import FuzzySearch from 'fuzzy-search' // Or: var FuzzySearch = require('fuzzy-search');

export default {
  name: 'RedminesWidget',
  components: {
    Filters,
    Issue
  },
  data () {
    return {
      itemsPerPage: 8,
      sortBy: 'updated_on',
      sortDesc: true
    }
  },
  methods: {
    nextPage () {
      if (this.page + 1 <= this.numberOfPages) this.page += 1
    },
    formerPage () {
      if (this.page - 1 >= 1) this.page -= 1
    },
    updateItemsPerPage (number) {
      this.itemsPerPage = number
    }
  },
  setup (props, context) {
    const { Issue, Search } = context.root.$FeathersVuex.api
    const { $store } = context.root

    // Load redmine user
    $store
      .dispatch('redmine/me/find', {
        query: {
          apiKey: $store.state.auth.user.redmineApiKey
        }
      })
      .then(me => {
        $store.state.redmineUserId = me[0].id

        //TODO: Figure out why these aren't automatically triggering sometimes
        if (!issues.value.length) {
          find(IssueParams)
        }

        if (!recentlyUpdated.value.length) {
          findRecentlyUpdated(RecentlyUpdatedIssueParams)
        }
      })

    const page = computed({
      get: () => $store.state.redminePage,
      set: val => {
        $store.dispatch('updateRedminePage', val)
      }
    })

    const searchTerm = computed({
      get: () => $store.state.redmineSearchTerm,
      set: val => {
        $store.dispatch('updateRedmineSearchTerm', val)
      }
    })

    const tab = computed({
      get: () => $store.state.redmineTab,
      set: val => {
        $store.dispatch('updateRedmineTab', val)
      }
    })

    const redmineUserId = computed({
      get: () => $store.state.redmineUserId,
      set: val => {
        $store.commit('SET_REDMINE_USER_ID', val)
      }
    })

    const allowQuery = computed(() => {
      let { redmineUserId } = $store.state
      return $store.state.auth.user.redmineApiKey &&
        redmineUserId &&
        redmineUserId !== '' &&
        !isSearchPending.value
        ? true
        : false
    })

    let tabs = ['Issues', 'Recently Updated']

    const AllIssueParams = computed(() => {
      const query = {}
      return {
        query: query
      }
    })

    const issueId = computed(() => {
      return searchTerm.value &&
        searchTerm.value.length === 5 &&
        !isNaN(parseInt(searchTerm.value))
        ? parseInt(searchTerm.value)
        : false
    })

    const IssueParams = computed(() => {
      let { redmineSearchTerm } = $store.state
      let {
        redmineProjectFilter,
        redmineStatusFilter,
        query = {
          'assigned_to.id': redmineUserId.value,
          $or: [{ recentlyUpdated: false }]
        }
      } = $store.state.userPreferences

      if ((redmineSearchTerm && searchItems.value.length) || issueId.value) {
        let searchIds = searchItems.value
          .filter(item => item.type.includes('issue'))
          .map(item => item.id)

        if (issueId.value) {
          searchIds.push(issueId.value)
        }

        query.$or.push({ id: { $in: searchIds } })

        delete query['assigned_to.id']
      }

      if (redmineProjectFilter.length) {
        query.project = {
          id: {
            $in: redmineProjectFilter
          }
        }
      }

      if (redmineStatusFilter.length) {
        query.status = {
          id: {
            $in: redmineStatusFilter
          }
        }
      }

      return {
        query: query,
        debounce: 250
      }
    })

    const RecentlyUpdatedIssueParams = computed(() => {
      let {
        redmineProjectFilter,
        redmineStatusFilter,
        filterRecentlyUpdated,
        query = {
          recentlyUpdated: true
        }
      } = $store.state.userPreferences

      if (filterRecentlyUpdated) {
        if (redmineProjectFilter.length) {
          query.project = {
            id: {
              $in: redmineProjectFilter
            }
          }
        }

        if (redmineStatusFilter.length) {
          query.status = {
            id: {
              $in: redmineStatusFilter
            }
          }
        }
      }

      return {
        query: query
      }
    })

    const SearchTermParams = computed(() => {
      return {
        query: { q: searchTerm.value },
        debounce: 500
      }
    })

    const {
      items: searchItems,
      isPending: isSearchPending,
      haveLoaded: searchItemsHaveLoaded
    } = useFind({
      model: Search,
      fetchParams: SearchTermParams,
      qid: 'searchItems'
    })

    const {
      items: issues,
      isPending,
      find: find,
      haveLoaded: issuesHaveLoaded
    } = useFind({
      model: Issue,
      params: IssueParams,
      queryWhen: allowQuery,
      qid: 'filteredIssues'
    })

    const { items: allIssues } = useFind({
      model: Issue,
      params: AllIssueParams,
      queryWhen: allowQuery,
      qid: 'allIssues'
    })

    const {
      items: recentlyUpdated,
      find: findRecentlyUpdated,
      isPending: isRecentlyUpdatedPending
    } = useFind({
      model: Issue,
      params: RecentlyUpdatedIssueParams,
      qid: 'recentIssues',
      queryWhen: allowQuery
    })

    const statuses = computed(() => {
      let statusIds = allIssues.value.map(issue => issue.status.id)
      let statuses = allIssues.value
        .map(issue => issue.status)
        .filter(function (item, i) {
          return statusIds.indexOf(item.id) === i
        })
      return statuses
    })

    const projects = computed(() => {
      let projectIds = issues.value
        .filter(
          issue =>
            issue.assigned_to && issue.assigned_to.id == redmineUserId.value
        )
        .map(issue => issue.project.id)
      let statuses = issues.value
        .map(issue => issue.project)
        .filter(function (item, i) {
          return projectIds.indexOf(item.id) === i
        })
      return statuses
    })

    const numberOfPages = computed(() => {
      return Math.ceil(filteredIssues.value.length / 8)
    })

    const filteredIssues = computed(() => {
      let { redmineTab, redmineSearchTerm } = $store.state

      let redmineIssues =
        redmineSearchTerm && redmineSearchTerm.length
          ? allIssues.value
          : redmineTab === 1
          ? recentlyUpdated.value
          : issues.value

      const searcher = new FuzzySearch(redmineIssues, ['subject', 'id'])

      let shouldSearch =
        (redmineSearchTerm &&
          redmineSearchTerm.length &&
          redmineSearchTerm !== null) ||
        false

      return shouldSearch ? searcher.search(redmineSearchTerm) : redmineIssues
    })

    watch(searchTerm, () => {
      if (!searchTerm.value || !searchTerm.value.length) {
        $store.commit('redmine/search/clearAll')
      }
    })

    const filterIssues = (items, search) => {
      const searcher = new FuzzySearch(items, ['subject', 'id'])
      return search ? searcher.search(search) : items
    }

    return {
      filteredIssues,
      recentlyUpdated,
      numberOfPages,
      items: issues,
      searchTerm,
      tab,
      tabs,
      issues,
      allIssues,
      isPending,
      find,
      statuses,
      projects,
      page,
      findRecentlyUpdated,
      isRecentlyUpdatedPending,
      RecentlyUpdatedIssueParams,
      IssueParams,
      searchItems,
      filterIssues,
      isSearchPending,
      issuesHaveLoaded,
      searchItemsHaveLoaded,
      allowQuery
    }
  }
}
</script>
