<template>
  <v-container fluid>
    <v-card v-if="show" class="mx-auto">
      <v-toolbar class="toolbarText--text" color="toolbar" flat>
        <v-spacer></v-spacer>
        <week-switcher />
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-progress-linear
        color="accent"
        :height="compact ? 5 : 18"
        :value="weeklyPercentage"
      >
        <template v-if="!compact">
          <small>{{ weeklyValue }} / {{ maxWeeklyHours }} hours</small>
        </template>
      </v-progress-linear>

      <v-row no-gutters>
        <v-col
          :class="columnClass"
          v-for="(summary, index) in parsedLogs"
          :key="index"
        >
          <SummaryDay :summary="summary" />
        </v-col>
      </v-row>

      <PriorityMappingDialog />
    </v-card>
  </v-container>
</template>

<script>
import moment from "moment"
import { computed } from "@vue/composition-api"
import { useFind } from "feathers-vuex"
import SummaryDay from "@/components/items/SummaryDay.vue"
import PriorityMappingDialog from "@/components/PriorityMappingDialog.vue"
import WeekSwitcher from "@/components/WeekSwitcher.vue"

export default {
  name: "WeeklySummary",
  components: {
    SummaryDay,
    PriorityMappingDialog,
    WeekSwitcher,
  },
  props: {
    showHeader: {
      default: true,
      type: Boolean,
    },
  },
  setup(props, context) {
    const { TimeEntry, Priority, TimeOffEntry, Holiday } =
      context.root.$FeathersVuex.api
    const { $store } = context.root
    const { mavenlinkId } = $store.state.auth.user

    const compact = computed(() =>
      ["/settings"].includes(context.root.$route.path)
    )
    const show = computed(() => !["/base"].includes(context.root.$route.path))
    const showWeekend = computed(
      () => $store.state.userPreferences.showWeekend
    )
    const columnClass = computed(() =>
      showWeekend.value ? "mx-auto" : "mx-auto pa-1"
    )

    // Queries
    const TimeEntryParams = computed(() => ({
      query: {
        $limit: 200,
        $sort: { date_performed: -1 },
        ...(mavenlinkId ? { user_id: `${mavenlinkId}` } : {}),
        date_performed: {
          $gte: moment($store.state.startDate).format("YYYY-MM-DD"),
          $lte: moment($store.state.endDate).format("YYYY-MM-DD"),
        },
      },
    }))
    const TimeOffEntryParams = computed(() => ({
      query: { $limit: 100, user_id: mavenlinkId },
    }))
    const PriorityParams = computed(() => $store.getters.priorityParams)

    const HolidayParams = computed(() => {
      return {
        query: {
          $limit: 200,
          $sort: { start_date: -1 },
        },
      }
    })

    // API calls
    const { items: timeOffEntries } = useFind({
      model: TimeOffEntry,
      params: TimeOffEntryParams,
    })
    const { items: priorities } = useFind({
      model: Priority,
      params: PriorityParams,
    })
    const { items: timeLogs } = useFind({
      model: TimeEntry,
      params: TimeEntryParams,
    })
    const { items: holidays } = useFind({
      model: Holiday,
      params: HolidayParams,
    })

    // Helper functions
    const groupBy = (xs, key) =>
      xs.reduce((rv, x) => {
        (rv[x[key]] = rv[x[key]] || []).push(x)
        return rv
      }, {})
    const getDates = (startDate, stopDate) => {
      let dateArray = []
      let currentDate = moment(startDate)
      while (currentDate <= moment(stopDate)) {
        dateArray.push(currentDate.format("YYYY-MM-DD"))
        currentDate.add(1, "days")
      }
      return dateArray
    }

    // Core logic
    const parsedLogs = computed(() => {
      const { startDate, endDate } = $store.state
      let weekDays = getDates(startDate, endDate)
      let groupLogs = groupBy(timeLogs.value, "date_performed")

      const weekDaysMapped = weekDays
        .map((day) => {
          const workPriorities = priorities.value
            .filter((v) => v.date === day)
            .map((priority) => {
              return {
                ...priority,
                hours: priority.minutes / 60,
                color: "primary",
              }
            })

          const timeOffEntriesForDay = timeOffEntries.value.filter(
            (v) => v.requested_date === day
          )

          const holidayEntriesForDay = holidays.value.filter(
            (v) =>
              v.start_date === day ||
              (v.end_date && moment(day).isBetween(v.start_date, v.end_date))
          )

          workPriorities.push(
            ...timeOffEntriesForDay.map((entry) => {
              return {
                ...entry,
                hours: entry.hours,
                color: "accent",
                workspace_title: "Paid Time Off",
                workspace_id: 0,
                time_off: true,
              }
            })
          )

          workPriorities.push(
            ...holidayEntriesForDay.map((entry) => {
              return {
                ...entry,
                hours: 8,
                color: "accent",
                workspace_title: entry.name,
                workspace_id: 0,
                time_off: true,
              }
            })
          )

          let totalTime = groupLogs[day]
            ? groupLogs[day]
                .reduce(
                  (a, b) => parseFloat(a) + parseFloat(b.time_in_minutes / 60),
                  0
                )
                .toFixed(2)
            : 0

          return {
            day: moment(day, "YYYY-MM-DD").format("dddd"),
            dayShort: moment(day, "YYYY-MM-DD").format("ddd"),
            dateShort: moment(day, "YYYY-MM-DD").format("ddd, MMM Do"),
            date: day,
            priorities: workPriorities,
            totalPriorities: 0,
            totalTime,
            time_off: timeOffEntriesForDay.length,
          }
        })
        .filter((item) => {
          const weekendDays = ["Saturday", "Sunday"]
          const isWeekendDay = weekendDays.includes(item.day)

          return (
            (isWeekendDay && item.totalTime > 0) ||
            !isWeekendDay ||
            $store.state.userPreferences.showWeekend
          )
        })
        .map((summary) =>
          Object.assign(summary, {
            color: summary.totalTime >= 8 ? "accent" : "primary",
          })
        )

      return weekDaysMapped
    })

    const maxWeeklyHours = computed(() => {
      const ptoEntriesForWeek = timeOffEntries.value.filter((v) =>
        parsedLogs.value.map((log) => log.date).includes(v.requested_date)
      )

      const totalPtoHours = ptoEntriesForWeek.reduce(
        (a, b) => parseFloat(a) + parseFloat(b.hours),
        0
      )

      return 40 - totalPtoHours
    })
    const weeklyPercentage = computed(() => {
      return (
        (parsedLogs.value
          .map((log) => parseFloat(log.totalTime))
          .reduce((a, b) => a + b, 0) /
          maxWeeklyHours.value) *
        100
      )
    })

    const weeklyValue = computed(() => {
      return parsedLogs.value
        .map((log) => parseFloat(log.totalTime))
        .reduce((a, b) => a + b, 0)
        .toFixed(2)
    })

    return {
      showWeekend,
      timeLogs,
      parsedLogs,
      weeklyPercentage,
      weeklyValue,
      compact,
      show,
      columnClass,
      maxWeeklyHours,
    }
  },
}
</script>
