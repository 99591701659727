<template>
  <div class="text-center">
    <v-menu :close-on-content-click="false" :nudge-width="200" offset-x>
      <template v-slot:activator="{ on }">
        <v-btn icon dark v-on="on">
          <v-badge
            v-if="redmineProjectFilter.length || redmineStatusFilter.length"
            content="!"
            color="warning"
          >
            <v-icon>mdi-dots-vertical</v-icon>
          </v-badge>
          <v-icon v-else>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-card max-width="400">
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Redmine</v-list-item-title>
              <v-list-item-subtitle>Filters</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-container>
          Projects
          <v-chip-group v-model="redmineProjectFilter" column multiple>
            <v-chip
              v-for="project in projects"
              :key="project.id"
              :value="project.id"
              filter
              outlined
              >{{ project.name }}</v-chip
            >
          </v-chip-group>
          Issue Status
          <v-chip-group v-model="redmineStatusFilter" column multiple>
            <v-chip
              v-for="status in statuses"
              :key="status.id"
              :value="status.id"
              filter
              outlined
              >{{ status.name }}</v-chip
            >
          </v-chip-group>
          Recently Updated Issues
          <v-switch
            v-model="filterRecentlyUpdated"
            label="Filter Recently Updated Issues"
          ></v-switch>
        </v-container>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { mapFields } from "vuex-map-fields"
export default {
  name: "RedminesWidget",
  props: {
    statuses: Array,
    projects: Array,
  },
  computed: {
    ...mapFields("userPreferences", [
      "redmineProjectFilter",
      "redmineStatusFilter",
      "filterRecentlyUpdated",
    ]),
  },
}
</script>
