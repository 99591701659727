<template>
  <v-timeline-item
    v-if="!(isDeclined && hideDeclinedMeetings)"
    class="mb-4"
    :color="
      isDeclined ? 'black' : event.logged ? 'accent' : isPast ? 'red' : 'grey'
    "
    small
  >
    <v-row justify="space-between">
      <v-col cols="7">
        <span :class="{ 'text-decoration-line-through': isDeclined }">{{
          event.summary
        }}</span>
        <br />
        <v-btn v-if="isConference" :href="conferenceUrl" target="_blank" class="mt-2 mr-2">
          <img :src="conferenceIcon" width="20" height="20" />
        </v-btn>

        <div class="mr-2" v-if="event.logged">
          <v-btn disabled class="mt-2 mr-2">Logged</v-btn>
          <v-btn
            v-if="event.timeEntry"
            class="mt-2"
            :loading="
              $store.state.loadingButtons.includes(event.timeEntry.logId)
            "
            @click="deleteTimeLog(event.timeEntry)"
          >
            <v-icon color="error"> mdi-trash-can </v-icon>
          </v-btn>
        </div>
        <v-btn
          :loading="$store.state.loadingButtons.includes(event.id)"
          @click="logTime(event)"
          v-else
          class="mt-2"
          ><v-icon class="mr-1">mdi-clock</v-icon> Log Time</v-btn
        >
      </v-col>
      <v-col class="text-right" cols="5">
        <span>
          {{ event.dateFormatted }}
        </span>
        <v-chip class="white--text ml-2" color="primary" label small>
          {{ event.duration }} minutes
        </v-chip>
      </v-col>
    </v-row>
  </v-timeline-item>
</template>
<script>
import moment from "moment"
import { mapActions, mapGetters, mapState } from "vuex"

export default {
  name: "TimelineItem",
  data: () => ({}),
  props: ["event"],
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapState("userPreferences", ["hideDeclinedMeetings"]),
    isPast() {
      return moment(this.event.end.dateTime).isBefore(moment())
    },
    isDeclined() {
      if (!this.event || !this.event.attendees) {
        return false
      }

      return !!this.event.attendees.find(
        (v) => v.responseStatus === "declined" && v.email === this.user.email
      )
    },
    isConference() {
      // If the event has already passed, don't show the conference button
      if (this.isPast) {
        return false
      }

      return this.event.conferenceData && this.event.conferenceData.entryPoints
    },
    conferenceIcon() {
      if (this.isConference) {
        return this.event.conferenceData.conferenceSolution.iconUri
      }

      return false
    },
    conferenceUrl() {
      if (this.isConference) {
        return this.event.conferenceData.entryPoints[0].uri
      }

      return false
    },
  },
  methods: {
    ...mapActions({
      storeLogTime: "time/logTime",
    }),
    isInternal(event) {
      let isInternal = false
      if (event.attendees && event.attendees.length) {
        isInternal = !!event.attendees.find(
          (v) => v.email === "team@orases.com"
        )
      }
      return isInternal
    },
    isInternalTeam(text) {
      let internalTeamNames = ["Fun Squad", "Wellness Committee"]
      let isInternal = false
      internalTeamNames.forEach((teamName) => {
        if (text.includes(teamName)) {
          isInternal = true
        }
      })
      return isInternal
    },
    logTime(event) {
      this.storeLogTime({
        show: true,
        description: event.summary,
        taskMatchString: event.summary,
        time: parseFloat(event.duration / 60).toFixed(2),
        event: event.id,
        date: moment(event.start.dateTime).format("YYYY-MM-DD"),
        loadingId: event.id,
        isInternal: this.isInternal(event),
        isInternalTeam: this.isInternalTeam(event.summary),
        task: event.task ? event.task.taskId : false,
        matchReasons: event.task
          ? ["Determined by past reoccuring events."]
          : [],
      })
    },
    async deleteTimeLog(log) {
      const logId = log.logId
      this.$store.dispatch("loadButton", log.logId)
      log = await this.$store.dispatch("mavenlink/time-entry/get", log.logId)

      this.$store.dispatch("unloadButton", logId)

      this.$parent.$parent.$parent.$refs.confirm
        .open(
          "Confirmation",
          `Are you sure you want to delete the time log <b>${log.notes}</b>?`,
          { color: "red" }
        )
        .then((confirm) => {
          if (confirm) {
            this.$store
              .dispatch("mavenlink/time-entry/remove", log.id)
              .then(() => {
                this.$store.dispatch("showToastSuccess", "Time log deleted.")
                this.$store.dispatch(
                  "google/calendar/find",
                  this.$store.getters.calendarParams
                )
              })
              .catch((err) => {
                this.$store.dispatch(
                  "showToastError",
                  `Error deleting time log. ${err.error}`
                )
              })
          }
        })
    },
  },
}
</script>
