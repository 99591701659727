import Vue from "vue"
import VueCompositionApi from "@vue/composition-api"
Vue.use(VueCompositionApi)

import App from "./App.vue"
import router from "./router"
import store from "./store"
import vuetify from "./plugins/vuetify"
import HelperMixin from "./mixins/helpers"

Vue.mixin(HelperMixin)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app")
