<template>
  <v-dialog v-model="priorityDialog.show" persistent max-width="600px">
    <v-card>
      <v-toolbar color="primary" dark :rounded="false">
        <v-toolbar-title>Mapping Required</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-alert type="error" text>
            There are some priorities that are not associated with a redmine
            project. Please select the proper mappings below.
          </v-alert>
          <v-row v-for="map in priorityDialog.maps" :key="map.id">
            <v-col cols="6">
              <v-text-field
                label="Priority"
                disabled
                :value="map.project"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-autocomplete
                item-value="id"
                item-text="name"
                :items="projects"
                v-model="map.redmine_id"
                label="Redmine Project"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-container>
        <v-divider></v-divider>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="warning" @click="priorityDialog.show = false">
          Cancel
        </v-btn>
        <v-btn :loading="loading" color="success" @click="saveMapping">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapFields } from "vuex-map-fields"
import { mapActions, mapGetters } from "vuex"
export default {
  name: "PriorityMappingDialog",
  computed: {
    ...mapFields(["priorityDialog", "priorityFilter"]),
    ...mapGetters({
      projects: "redmine/project/list",
      priorityParams: "priorityParams",
    }),
  },
  data() {
    return {
      loading: false,
    }
  },
  mounted() {
    this.$store.dispatch("redmine/project/find", { query: { $limit: 200 } })
  },
  methods: {
    ...mapActions(["filterByPriority"]),
    saveMapping() {
      this.loading = true
      let priorities = this.priorityDialog.maps.map((priority) =>
        this.$store.dispatch("priorities/create", priority)
      )
      Promise.all(priorities)
        .then(() => {
          this.priorityDialog.show = false

          this.$store.dispatch(
            "showToastSuccess",
            "Mappings have been updated."
          )
          this.$store
            .dispatch("priorities/find", this.priorityParams)
            .then(() => {
              this.filterByPriority(this.priorityDialog.date)
            })
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
