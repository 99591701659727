<template>
  <v-container fluid>
    <v-alert v-if="alerts && alerts.length" type="error">
      {{ alerts[0].message }}
    </v-alert>
    <v-row fluid class="">
      <v-col sm="12" md="6">
        <template v-if="showIssueTracker">
          <RedminesWidget v-if="issueTracker === 'redmine' && enableRedmine" />
          <JiraWidget v-else />
          <br />
        </template>
        <MavenlinkWidget />
      </v-col>
      <v-col sm="12" md="6">
        <GoogleCalendarWidget />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RedminesWidget from "../components/RedminesWidget"
import JiraWidget from "../components/JiraWidget"
import MavenlinkWidget from "../components/MavenlinkWidget"
import GoogleCalendarWidget from "../components/GoogleCalendarWidget"
import { mapFields } from "vuex-map-fields"
import { makeFindMixin } from "feathers-vuex"
import { mapGetters } from "vuex"
export default {
  name: "Dashboard",
  components: {
    RedminesWidget,
    JiraWidget,
    MavenlinkWidget,
    GoogleCalendarWidget,
  },
  computed: {
    ...mapFields(["enableRedmine"]),
    ...mapGetters("auth", ["user"]),
    ...mapFields("userPreferences", ["issueTracker", "showIssueTracker"]),
    alertsParams() {
      return { query: {} }
    },
  },
  mixins: [makeFindMixin({ service: "alerts" })],
  mounted() {},
}
</script>
