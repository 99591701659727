<template>
  <div>
    <div v-if="issue && estimatedHours && spentHours !== false">
      Spent Hours: {{ parseFloat(spentHours).toFixed(2) }}
      <span>/ {{ estimatedHours || "∞" }} hours</span>
      <v-progress-linear
        :value="spentPercentage"
        :striped="estimatedHours <= 0"
        :color="
          !estimatedHours
            ? 'primary'
            : spentPercentage > 90
            ? spentPercentage > 100
              ? 'red'
              : 'orange'
            : 'success'
        "
      ></v-progress-linear>
      <v-alert
        v-if="estimatedHours && spentPercentage > 200"
        color="warning"
        class="mt-4"
      >
        Spent hours are more than <b>double</b> what was estimated. Are you sure
        this is correct?
      </v-alert>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  name: "SpentHoursWarning",
  props: {
    dialog: {
      type: Object,
    },
    updateSpentHours: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters("time", ["spentPercentage", "spentHours"]),

    issue() {
      if (this.dialog && this.dialog.issue) {
        return this.dialog.issue
      }
      return {}
    },

    estimatedHours() {
      if (this.issue.estimated_hours) {
        return parseFloat(this.issue.estimated_hours).toFixed(2)
      }
      if (this.issue.fields && this.issue.fields.timeoriginalestimate) {
        return parseFloat(
          (this.issue.fields.timeoriginalestimate || 0) / 3600
        ).toFixed(2)
      }

      return 0
    },
  },
}
</script>
