<template>
  <v-container fluid>
    <v-card class="mx-auto mb-4">
      <v-toolbar class="toolbarText--text" color="toolbar" flat>
        <v-toolbar-title>Unsubmitted Time Entries</v-toolbar-title>
        <v-spacer></v-spacer>
        <week-switcher />

        <v-btn
          :loading="isFindTimeEntryPending"
          class="toolbarText--text"
          @click="findTimeEntry(timeEntryParams)"
          icon
        >
          <v-icon> mdi-refresh </v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pa-0">
        <v-data-table
          :group-by="['workspace.title']"
          group-desc
          sort-by="start_date"
          sort-desc
          disable-sort
          :headers="timeEntriesHeaders"
          :items="parsedTimeEntries"
          :items-per-page="10"
          :hide-default-footer="false"
          :disable-pagination="false"
          search
          :loading="isFindTimeEntryPending"
          class="elevation-1"
          :footer-props="{
            'items-per-page-options': [10, 25, 50, 100],
          }"
        >
          <template v-slot:[`group.header`]="{ items }">
            <td colspan="3">
              <b>{{ items[0].workspace.title }} </b>
            </td>
            <td>
              <b>{{ items.length }} time entries</b>
            </td>
            <td>
              <b>
                {{
                  items
                    .reduce((a, b) => +a + +(b.time_in_minutes / 60), 0)
                    .toFixed(2)
                }}
                hours
              </b>
            </td>
            <template v-if="$vuetify.breakpoint.smAndUp"> </template>
          </template>
          <template v-slot:progress>
            <v-progress-linear
              :indeterminate="isFindTimeEntryPending"
              absolute
              bottom
              color="accent"
            ></v-progress-linear>
          </template>
          <template v-slot:[`item.story.title`]="{ item }">
            {{ item.story ? item.story.title : "Awaiting sync..." }}
            <v-progress-linear
              v-if="!item.synced"
              buffer-value="0"
              stream
            ></v-progress-linear>
          </template>

          <template
            v-for="header in timeEntriesHeaders.filter((header) =>
              header.hasOwnProperty('formatter')
            )"
            v-slot:[`item.${header.value}`]="{ value }"
          >
            {{ header.formatter(value) }}
          </template>
        </v-data-table>
      </v-card-text>
      <v-toolbar class="toolbarText--text" color="grey darken-2" flat>
        <v-toolbar-title>
          <b>
            Total Hours:
            {{
              parsedTimeEntries
                .reduce((a, b) => +a + +(b.time_in_minutes / 60), 0)
                .toFixed(2)
            }}
            / 40.00
          </b>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          target="_blank"
          href="https://orases.mavenlink.com/timesheets"
          color="primary"
          class="mr-2"
        >
          View in MavenLink
        </v-btn>

        <v-btn
          :disabled="!isSubmissionAllowed"
          @click="showDialog = true"
          color="success"
          >{{ submitTimesheetTitle }}</v-btn
        >
      </v-toolbar>
    </v-card>
    <v-data-table
      :group-by="['date_range']"
      group-desc
      sort-by="start_date"
      sort-desc
      disable-sort
      :headers="usersHeaders"
      :items="items"
      :items-per-page="50"
      :hide-default-footer="false"
      :disable-pagination="false"
      search
      :loading="isFindTimesheetSubmissionPending"
      class="elevation-1"
      :footer-props="{
        'items-per-page-options': [50, 100],
      }"
    >
      <template v-slot:[`item.status`]="{ item }">
        <v-chip small :color="getChipColor(item.status)">{{
          item.status
        }}</v-chip>
      </template>
      <template v-slot:progress>
        <v-progress-linear
          :indeterminate="isFindTimesheetSubmissionPending"
          absolute
          bottom
          color="accent"
        ></v-progress-linear>
      </template>
      <template v-slot:top>
        <v-toolbar
          style="border-radius: 4px 4px 0px 0px"
          class="toolbarText--text"
          color="toolbar"
        >
          <v-toolbar-title>Timesheets</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            :loading="isFindTimesheetSubmissionPending"
            class="toolbarText--text"
            @click="findTimesheetSubmission(timesheetSubmissionParams)"
            icon
          >
            <v-icon> mdi-refresh </v-icon>
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        {{ formatDate(item.created_at) }}
      </template>
      <template v-slot:[`group.header`]="{ items }">
        <td colspan="4">
          <b
            >{{ formatDate(items[0].start_date) }} to
            {{ formatDate(items[0].end_date) }}
          </b>
        </td>
        <td>
          <b>{{ items.length }} time sheets</b>
        </td>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn class="ml-2" icon @click="edit(item)">
          <v-icon color="primary"> mdi-pencil </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-dialog v-model="showDialog" scrollable max-width="800px">
      <v-card>
        <v-toolbar color="primary" dark :rounded="false">
          <v-toolbar-title>Submit Timesheet</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-container fluid>
            <v-template v-if="hasUnsyncedTimeEntries">
              <v-alert type="warning" text>
                You currently have pending time entries. Your timesheet will be
                submitted to MavenLink once all time entries are synced.
              </v-alert>
              <v-divider></v-divider>
            </v-template>
            <v-template v-else>
              <v-alert class="font-weight-bold" color="grey"
                >Are you sure you want to submit your timesheet for the week of
                {{ formatDateHuman(startDate) }} to
                {{ formatDateHuman(endDate) }}?</v-alert
              >
              <v-divider></v-divider>
            </v-template>

            <v-row no-gutters>
              <v-col cols="12">
                <v-list-item
                  v-for="total in timesheetTotals"
                  :key="total.title"
                >
                  <v-list-item-avatar>
                    <v-icon class="grey lighten-1" dark> mdi-clock </v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>{{ total.title }}</v-list-item-title>
                    <v-list-item-subtitle>
                      {{ total.time_entries_count }} time entries
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-btn small disabled tile>
                      {{ total.time_entries_total / 60 }} hrs
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="grey d-flex pt-4 pb-4">
          <v-btn tile color="secondary darken-1" @click="showDialog = false"
            >Cancel</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn tile @click="save" color="success darken-1">{{
            hasUnsyncedTimeEntries ? "Schedule Submission" : "Submit"
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { makeFindMixin } from "feathers-vuex"
import moment from "moment"
import { mapFields } from "vuex-map-fields"
import WeekSwitcher from "@/components/WeekSwitcher.vue"

export default {
  components: {
    WeekSwitcher,
  },
  data() {
    return {
      user: false,
      showDialog: false,
      valid: false,
      timeEntriesHeaders: [
        {
          value: "icon",
        },
        {
          text: "Project",
          value: "workspace.title",
        },
        {
          text: "Task",
          value: "story.title",
        },
        {
          text: "Description",
          value: "notes",
          align: "start",
        },
        {
          text: "Date",
          value: "date_performed",
          formatter: this.formatDate,
        },
        {
          text: "Time",
          value: "time_in_minutes",
          formatter: (val) => `${val / 60}`,
        },
      ],
      usersHeaders: [
        {
          text: "Project",
          value: "workspace.title",
        },
        {
          text: "Status",
          value: "status",
        },
        {
          text: "Start Date",
          value: "start_date",
        },
        {
          text: "End Date",
          value: "end_date",
        },

        {
          text: "Created ",
          value: "created_at",
        },
      ],
    }
  },
  mixins: [
    makeFindMixin({
      service: "mavenlink/timesheet-submission",
    }),
    makeFindMixin({
      service: "mavenlink/time-entry",
    }),
    makeFindMixin({
      service: "mavenlink/story",
    }),
    makeFindMixin({
      service: "mavenlink/workspace",
    }),
    makeFindMixin({
      service: "pending-timesheets",
    }),
  ],
  computed: {
    ...mapFields(["startDate", "endDate"]),
    isSubmissionAllowed() {
      // If there are any pending timesheets that haven't been processed, don't allow submission
      if (this.pendingTimesheets.some((timesheet) => !timesheet.processed)) {
        return false
      }

      if (!this.timeEntry.length) {
        return false
      }

      return true
    },

    submitTimesheetTitle() {
      if (this.timeEntry.length === 0) {
        return "No Time Entries"
      }

      if (this.isTimesheetSubmitted && !this.timeEntry.length) {
        return "Timesheet Submitted"
      }

      return this.hasPendingTimesheet
        ? "Timesheet Submission Queued"
        : "Submit for Approval"
    },
    currentTimesheetSubmissions() {
      return this.timesheetSubmission.filter((t) => {
        return (
          moment(t.start_date).isSameOrAfter(this.startDate, "day") &&
          moment(t.end_date).isSameOrBefore(this.endDate, "day")
        )
      })
    },
    isTimesheetSubmitted() {
      return this.currentTimesheetSubmissions.length > 0
    },
    hasPendingTimesheet() {
      return (
        this.pendingTimesheets.length > 0 &&
        this.pendingTimesheets.filter((timesheet) => !timesheet.processed)
          .length > 0
      )
    },
    pendingTimesheet() {
      if (!this.pendingTimesheets.length) {
        return false
      }

      return this.pendingTimesheets[0]
    },
    hasUnsyncedTimeEntries() {
      return (
        this.timeEntry.filter((entry) => entry.is_local && !entry.synced)
          .length > 0
      )
    },
    timesheetTotals() {
      const totals = []

      // Get all unique workspaces from time entries
      const workspaces = this.timeEntry.reduce((acc, entry) => {
        if (!acc.includes(entry.workspace_id)) {
          acc.push(entry.workspace_id)
        }
        return acc
      }, [])

      const workspaceObjects = workspaces.map((workspace) => {
        return this.timeEntry.find((t) => t.workspace_id === workspace)
          .workspace
      })

      for (const workspace of workspaces) {
        const workspaceObject = workspaceObjects.find(
          (ws) => ws.id === workspace
        )

        const timeEntries = this.timeEntry.filter(
          (entry) => entry.workspace_id === workspace
        )

        const timeEntriesTotal = timeEntries.reduce(
          (acc, entry) => acc + entry.time_in_minutes,
          0
        )

        const total = {
          time_entries_total: timeEntriesTotal,
          time_entries_count: timeEntries.length,
          title: workspaceObject ? workspaceObject.title : "Pending",
        }

        totals.push(total)
      }

      return totals
    },
    timeEntryParams() {
      const { mavenlinkId } = this.$store.state.auth.user

      return {
        query: {
          in_active_submission: false,
          approved: false,
          $limit: 50,
          $sort: { id: -1 },
          date_performed: {
            $gte: moment(this.startDate).format("YYYY-MM-DD"),
            $lte: moment(this.startDate).add(7, "days").format("YYYY-MM-DD"),
          },
          ...(mavenlinkId ? { user_id: `${mavenlinkId}` } : {}),
        },
      }
    },
    timesheetSubmissionParams() {
      return { query: { $limit: 50, $sort: { id: -1 } } }
    },
    storyParams() {
      return { query: { $limit: 200, $sort: { id: -1 } } }
    },
    workspaceParams() {
      return { query: { $limit: 200, $sort: { id: -1 } } }
    },
    pendingTimesheetsParams() {
      const personId = this.$store.state.auth.user.mavenlinkId
      const startDate = moment(this.startDate).format("YYYY-MM-DD")
      const endDate = moment(this.endDate).format("YYYY-MM-DD")
      return { query: { startDate, endDate, personId, $limit: 200 } }
    },
    parsedTimeEntries() {
      // Create an array and group time entries by workspace_id
      const groupedTimeEntries = this.timeEntry.reduce((r, a) => {
        const existingEntry = r.find((x) => x.story_id === a.story_id)

        if (!existingEntry) {
          r.push({ ...a, count: 1 })
        } else {
          // Add time_in_minutes to existing entry
          existingEntry.time_in_minutes += a.time_in_minutes
          existingEntry.count++
          existingEntry.notes = `${existingEntry.count} time entries`
        }

        return r
      }, [])

      return [...Array.from(groupedTimeEntries)].map((entry) => {
        const story = this.story.find(
          (story) => story.id == entry.story_id
        ) || { workspace: { title: "N/A" } }

        return {
          ...entry,
          project: story.workspace.title,
          task: story.title,
          notes: entry.notes,
          date_performed: entry.date_performed,
          time_in_minutes: entry.time_in_minutes,
          billable: entry.billable,
        }
      })
    },
    projectSummary() {
      // Group time entry by workspace_id
      const grouped = this.parsedTimeEntries.reduce((r, a) => {
        r[a.workspace_id] = [...(r[a.workspace_id] || []), a]
        return r
      }, {})

      // Map over the grouped object and return an array of objects
      return Object.keys(grouped).map((key) => {
        const entries = grouped[key]
        const total = entries.reduce((acc, entry) => {
          return acc + entry.time_in_minutes
        }, 0)

        return {
          workspace_title: entries[0].workspace.title,
          workspace_id: key,
          total,
          entries,
        }
      })
    },
    items() {
      const submissions = [...this.timesheetSubmission]

      return submissions
        .sort((a, b) => {
          return new Date(b.start_date) - new Date(a.start_date)
        })
        .reverse()
    },
  },
  methods: {
    moveBack() {
      this.startDate = moment(this.startDate)
        .subtract(7, "days")
        .format("YYYY-MM-DD")
      this.endDate = moment(this.endDate)
        .subtract(7, "days")
        .format("YYYY-MM-DD")
    },
    moveForward() {
      this.startDate = moment(this.startDate)
        .add(7, "days")
        .format("YYYY-MM-DD")
      this.endDate = moment(this.endDate).add(7, "days").format("YYYY-MM-DD")
    },

    edit(user) {
      this.user = Object.assign({}, user)
      this.showDialog = true
    },
    save() {
      this.$store.dispatch("pending-timesheets/create", {
        personId: this.user.mavenlinkId,
        startDate: moment(this.startDate).format("YYYY-MM-DD"),
        endDate: moment(this.endDate).format("YYYY-MM-DD"),
        processed: false,
      })
      this.showDialog = false
      this.$store.dispatch(
        "showToastSuccess",
        this.hasUnsyncedTimeEntries
          ? "Timesheet will be submitted once un-synced entries finish syncing."
          : "Timesheet has been submitted."
      )
    },
    getChipColor(status) {
      const colorMap = {
        Submitted: "success",
        Pending: "warning",
        Rejected: "error",
        Approved: "success",
      }

      return colorMap[status] || "grey"
    },
  },
  mounted() {},
  watch: {
    hasPendingTimesheet() {
      this.findTimesheetSubmission(this.timesheetSubmissionParams).then(() => {
        this.$store.commit("mavenlink/time-entry/clearAll")
        this.findTimeEntry(this.timeEntryParams)
      })
    },
  },
}
</script>
