<template>
  <v-navigation-drawer
    :clipped="$vuetify.breakpoint.lgAndUp"
    permanent
    right
    app
    width="500"
    v-if="showDialog"
  >
    <template v-slot:prepend>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title>{{
            dialog.modify ? "Modify Time Entry" : "Add Time Entry"
          }}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn-toggle v-model="dialogPlacement" tile group>
            <v-btn value="modal">
              Modal
            </v-btn>
            <v-btn value="sidebar">
              Sidebar
            </v-btn>
          </v-btn-toggle>
        </v-list-item-action>
      </v-list-item>
    </template>
    <v-divider></v-divider>
    <TimeForm></TimeForm>
  </v-navigation-drawer>
</template>

<script>
import { createHelpers, mapFields } from "vuex-map-fields"
import TimeForm from "@/components/form/TimeForm.vue"

const { mapFields: scopedMapFields } = createHelpers({
  getterType: "time/getField",
  mutationType: "time/updateField",
})

export default {
  name: "TimeDialog",

  components: { TimeForm },
  computed: {
    ...mapFields("userPreferences", ["dialogPlacement"]),
    ...scopedMapFields({
      dialog: "timeDialog",
      showDialog: "timeDialog.show",
    }),
  },
}
</script>
