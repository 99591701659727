<template>
  <v-hover>
    <template v-slot:default="{ hover }">
      <v-card
        style="width: 100%; flex-direction: column"
        class="d-flex w-100 pa-2"
        flat
        :color="
          isFilterEnabled(summary)
            ? $vuetify.theme.dark
              ? 'grey darken-3'
              : 'grey lighten-5'
            : $vuetify.theme.dark
            ? 'grey darken-4'
            : 'white'
        "
        :elevation="isFilterEnabled(summary) ? 2 : 0"
      >
        <v-fade-transition
          v-if="
            !isPrioritiesPending &&
            summary.priorities.length &&
            $route.name === 'Dashboard'
          "
        >
          <v-overlay v-if="hover && 1 === 2" absolute color="#3b3b3b">
            <v-btn @click="filterByPriority(summary.date)" color="secondary">
              <template v-if="!isFilterEnabled(summary)">
                Filter Redmines <v-icon right>mdi-filter</v-icon>
              </template>
              <template v-else>
                Disable Filter <v-icon right>mdi-filter-off</v-icon>
              </template>
            </v-btn>
          </v-overlay>
        </v-fade-transition>

        <v-progress-circular
          :rotate="270"
          :size="compact ? 65 : 70"
          :width="compact ? 5 : 7"
          :value="(summary.totalTime / (summary.totalPriorities || 8)) * 100"
          :color="
            summary.totalTime < (summary.totalPriorities || 8)
              ? 'progressColor'
              : 'progressColorComplete'
          "
          class="mx-auto font-weight-bold"
        >
          <template>
            <div
              class="d-flex text-center"
              style="width: 100%; flex-direction: column"
            >
              <template v-if="!summary.time_off">
                <span>
                  {{ summary.totalTime }}
                </span>
                <small v-if="compact">{{ summary.dayShort }}</small>
              </template>
              <template v-else>
                <span style="font-size: 2rem"> ⛱️ </span>
              </template>
            </div>
          </template>
        </v-progress-circular>

        <div v-if="!compact" style="width: 80%" class="mx-auto text-center">
          {{ summary.dateShort }}
          <v-progress-linear
            v-if="isPrioritiesPending"
            color="accent"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
          <v-row
            no-gutters
            v-if="!isPrioritiesPending"
            class="pt-2"
            justify="center"
            align="center"
          >
            <v-col
              :key="`${priority.workspace_id}-${priority.date}`"
              v-for="priority in summary.priorities"
              cols="12"
            >
              <v-badge overlap style="width: 100%" :color="priority.color">
                <template v-slot:badge>
                  <span
                    :class="
                      'font-weight-bold ' +
                      (lightText(priority.color)
                        ? 'black--text'
                        : 'white--text')
                    "
                    >{{ priority.hours }} H</span
                  >
                </template>
                <v-chip
                  small
                  style="width: 100%"
                  :class="`mt-1 font-weight-bold ${
                    lightText(priority.color) ? 'black--text' : 'white--text'
                  }`"
                  label
                  :color="priority.color"
                >
                  {{ truncate(priority.workspace_title, 20) }}
                </v-chip>
              </v-badge>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </template>
  </v-hover>
</template>
<script>
import { computed } from "@vue/composition-api"
import { mapFields } from "vuex-map-fields"
import { mapActions } from "vuex"
export default {
  name: "WeeklySummary",
  props: ["hover", "summary", "compact", "isPrioritiesPending"],
  computed: {
    ...mapFields(["priorityDialog"]),
  },
  methods: {
    ...mapActions(["filterByPriority"]),
    isFilterEnabled(summary) {
      return this.priorityFilter === summary.date
    },
  },
  setup(props, context) {
    let { $store } = context.root

    const priorityFilter = computed({
      get: () => $store.state.priorityFilter,
      set: (val) => {
        $store.state.priorityFilter = val
      },
    })

    const truncate = (str, num) =>
      str.length > num ? str.slice(0, num) + "..." : str

    const lightText = (bgColor) => {
      var color = bgColor.charAt(0) === "#" ? bgColor.substring(1, 7) : bgColor
      var r = parseInt(color.substring(0, 2), 16) // hexToR
      var g = parseInt(color.substring(2, 4), 16) // hexToG
      var b = parseInt(color.substring(4, 6), 16) // hexToB
      return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? true : false
    }

    return {
      find,
      truncate,
      lightText,
      priorityFilter,
    }
  },
}
</script>
