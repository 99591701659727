<template>
  <div>
    <template v-if="type === 'jira'">
      <v-divider v-if="index > 0" :key="index"></v-divider>
      <v-list-item :key="issue.id" two-line>
        <v-list-item-avatar>
          <img
            v-if="issue.fields.assignee"
            :src="issue.fields.assignee.avatarUrls['48x48']"
          />
          <span v-else>N/A</span>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            <span class=""
              ><a
                class="primary--text"
                target="_blank"
                :href="`${jiraBasePath}${issue.key}`"
                >#{{ issue.key }}</a
              >
              | {{ issue.fields.summary }}</span
            >
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ issue.fields.project.name }}
            {{
              issue.fields.assignee
                ? ` - ${issue.fields.assignee.displayName}`
                : ""
            }}
            <br />

            <v-chip
              label
              small
              class="mt-2 ml-0"
              :color="
                colorMap[issue.fields.status.name]
                  ? colorMap[issue.fields.status.name]
                  : 'grey'
              "
              text-color="white"
            >
              {{ issue.fields.status.name }}
            </v-chip>
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn
            :loading="$store.state.loadingButtons.includes(issue.key)"
            @click="logTime(issue)"
          >
            <v-icon class="mr-1">mdi-clock</v-icon> Log Time
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </template>
    <template v-else>
      <v-divider v-if="index > 0" :key="index"></v-divider>
      <v-list-item :key="issue.id" two-line>
        <v-list-item-content>
          <v-list-item-title>
            <span class=""
              ><a
                class="primary--text"
                target="_blank"
                :href="`${redmineBasePath}${issue.id}`"
                >#{{ issue.id }}</a
              >
              | {{ issue.subject }}</span
            >
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ issue.project.name }}
            {{ issue.assigned_to ? ` - ${issue.assigned_to.name}` : "" }}
            <br />

            <v-chip
              label
              small
              class="mt-2 ml-0"
              :color="
                colorMap[issue.status.name]
                  ? colorMap[issue.status.name]
                  : 'grey'
              "
              text-color="white"
            >
              {{ issue.status.name }}
            </v-chip>
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn
            :loading="$store.state.loadingButtons.includes(issue.id)"
            @click="logTime(issue)"
          >
            <v-icon class="mr-1">mdi-clock</v-icon> Log Time
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </template>
  </div>
</template>
<script>
export default {
  name: "IssueItem",
  props: ["issue", "index", "type"],
  setup(props, context) {
    const { $store } = context.root
    const { issueTracker } = $store.state.userPreferences

    const logTime = (issue) => {
      if (issueTracker === "jira") {
        $store.dispatch("loadButton", issue.key)
        $store.dispatch("jira/issue/get", issue.key).then((jiraIssue) => {
          let payload = {
            description: `#${jiraIssue.key} - ${jiraIssue.fields.summary}`,
            time: null,
            loadingId: jiraIssue.key,
            taskMatchString: jiraIssue.fields.project.name,
            issue: jiraIssue,
            task: jiraIssue.previousLog ? jiraIssue.previousLog.taskId : null,
          }

          if (jiraIssue.previousLog) {
            Object.assign(payload, {
              task: jiraIssue.previousLog.taskId,
            })
          }

          $store.dispatch("time/logTime", payload)
        })
      } else {
        $store.dispatch("loadButton", issue.id)
        $store.dispatch("redmine/issue/get", issue.id).then((issue) => {
          $store.dispatch("time/logTime", {
            description: `#${issue.id} - ${issue.subject}`,
            time: null,
            loadingId: issue.id,
            taskMatchString: issue.project.name,
            issue: issue,
          })
        })
      }
    }

    const colorMap = {
      "Go Live": "green",
      "Ready for QA": "blue",
      "Needs Estimate": "red",
      "Awaiting Feedback": "red",
      "In Progress": "orange",
      Open: "primary",
      Assigned: "green",
    }

    return {
      colorMap,
      logTime,
    }
  },
  data() {
    return {
      redmineBasePath: "https://redmine.orases.com/issues/",
      jiraBasePath: "https://orases.atlassian.net/browse/",
    }
  },
}
</script>
