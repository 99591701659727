<template>
  <div>
    <v-divider v-if="index > 0" :key="index"></v-divider>
    <v-list-item :key="story.id" two-line>
      <v-list-item-content>
        <v-list-item-title>
          <a
            target="_blank"
            class="primary--text"
            :href="`https://orases.mavenlink.com/workspaces/${story.workspace_id}/#tracker/${story.id}`"
            >#{{ story.id }}</a
          >
          | {{ unescape(story.title) }}
        </v-list-item-title>
        <v-list-item-subtitle v-html="story.workspace.title" />
      </v-list-item-content>
      <v-list-item-action>
        <v-btn @click="logTime(story)">
          <v-icon
            :loading="$store.state.loadingButtons.includes(story.id)"
            class="mr-1"
            >mdi-clock</v-icon
          >
          Log Time
        </v-btn>
      </v-list-item-action>
    </v-list-item>
  </div>
</template>
<script>
export default {
  name: "StoryItem",
  props: ["story", "index"],
  setup(props, context) {
    const { $store } = context.root

    const logTime = (story) => {
      $store.dispatch("time/logTime", {
        show: true,
        task: story.id,
        projectid: story.projectid,
        directLog: true,
      })
    }

    return {
      logTime,
    }
  },
}
</script>
