import Vuetify from "../plugins/vuetify"
import { getField, updateField } from "vuex-map-fields"

const preferences = {
  namespaced: true,
  state: {
    showWeekend: false,
    darkMode: false,
    redmineProjectFilter: [],
    redmineStatusFilter: [],
    showTodayPanel: true,
    updateSpentHours: true,
    filterRecentlyUpdated: false,
    dialogPlacement: "modal",
    issueTracker: "redmine",
    jiraProject: "",
    mavenlinkProjectFilter: [],
    mavenlinkStatusFilter: [],
    mavenlink: {
      showFilter: true,
    },
    showIssueTracker: true,
    showTodayWidget: true,
    hideDeclinedMeetings: false
  },
  mutations: {
    updateField,
    SET_UPDATE_SPENT_HOURS(state, payload) {
      state.updateSpentHours = payload
    },
    SET_TODAY_PANEL(state, payload) {
      state.showTodayPanel = payload
    },
    SET_DARK_MODE(state, payload) {
      state.darkMode = payload
    },
    SET_REDMINE_PROJECT_FILTER(state, payload) {
      state.redmineProjectFilter = payload
    },
    SET_REDMINE_STATUS_FILTER(state, payload) {
      state.redmineStatusFilter = payload
    },
    SET_FILTER_RECENTLY_UPDATED(state, payload) {
      state.filterRecentlyUpdated = payload
    },
    SET_JIRA_PROJECT(state, payload) {
      state.jiraProject = payload
    },
  },
  actions: {
    updateSpentHours: ({ commit, state }, newValue) => {
      commit("SET_UPDATE_SPENT_HOURS", newValue)
      return state.updateSpentHours
    },
    toggleTodayPanel: ({ commit, state }, newValue) => {
      commit("SET_TODAY_PANEL", newValue)
      return state.showTodayPanel
    },
    toggleDarkMode: (context, newValue) => {
      context.commit("SET_DARK_MODE", newValue)
      // Update vuetify theme
      Vuetify.framework.theme.dark = newValue
      return context.state.darkMode
    },
    setRedmineStatusFilter: ({ commit, state }, newValue) => {
      commit("SET_REDMINE_STATUS_FILTER", newValue)
      return state.redmineStatusFilter
    },
    setRedmineProjectFilter: ({ commit, state }, newValue) => {
      commit("SET_REDMINE_PROJECT_FILTER", newValue)
      return state.redmineProjectFilter
    },
    setFilterRecentlyUpdated: ({ commit, state }, newValue) => {
      commit("SET_FILTER_RECENTLY_UPDATED", newValue)
      return state.filterRecentlyUpdated
    },
  },
  getters: { getField },
}

export default preferences
