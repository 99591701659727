<template>
  <div>
    <div>
      <v-btn @click="moveBack" class="toolbarText--text" icon>
        <v-icon> mdi-arrow-left </v-icon>
      </v-btn>
      <v-badge :class="{ 'font-weight-bold': isCurrentWeek }">
        {{ formatDateHuman(startDate) }} to
        {{ formatDateHuman(endDate) }}
      </v-badge>
      <v-btn
        :disabled="!isNextWeekAvailable"
        @click="moveForward"
        class="toolbarText--text"
        icon
      >
        <v-icon> mdi-arrow-right </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import moment from "moment"
import { mapFields } from "vuex-map-fields"
import { mapGetters } from "vuex"

export default {
  data() {
    return {}
  },
  computed: {
    ...mapFields(["startDate", "endDate"]),
    ...mapGetters(["isCurrentWeek", "isNextWeekAvailable"]),
  },
  methods: {
    moveBack() {
      this.startDate = moment(this.startDate)
        .subtract(7, "days")
        .format("YYYY-MM-DD")
      this.endDate = moment(this.endDate)
        .subtract(7, "days")
        .format("YYYY-MM-DD")
    },
    moveForward() {
      this.startDate = moment(this.startDate)
        .add(7, "days")
        .format("YYYY-MM-DD")
      this.endDate = moment(this.endDate).add(7, "days").format("YYYY-MM-DD")
    },
  },
  mounted() {
    this.startDate = moment().startOf("week").format("YYYY-MM-DD")
    this.endDate = moment().endOf("week").format("YYYY-MM-DD")
  },
}
</script>
