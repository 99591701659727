var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"group-by":['date_performed'],"sort-by":['date_performed'],"group-desc":true,"headers":_vm.timeLogsHeaders,"items":_vm.parsedTimeLogs,"items-per-page":50,"hide-default-footer":false,"disable-pagination":false,"search":"","loading":_vm.isPending,"footer-props":{
      'items-per-page-options': [50, 100],
    }},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"indeterminate":_vm.isPending,"absolute":"","bottom":"","color":"accent"}})]},proxy:true},{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"toolbarText--text",staticStyle:{"border-radius":"4px 4px 0px 0px"},attrs:{"color":"toolbar"}},[_c('v-toolbar-title',[_vm._v("Time Logs")]),_c('v-spacer'),_c('v-btn',{staticClass:"toolbarText--text",attrs:{"loading":_vm.isPending,"icon":""},on:{"click":_vm.find}},[_c('v-icon',[_vm._v(" mdi-refresh ")])],1),_c('v-btn',{staticClass:"toolbarText--text",attrs:{"icon":""},on:{"click":function($event){return _vm.$store.dispatch('time/logTime', {})}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)]},proxy:true},{key:"group.header",fn:function(ref){
    var items = ref.items;
return [_c('td'),(_vm.$vuetify.breakpoint.smAndUp)?[_c('td',{attrs:{"colspan":"2"}},[_c('b',[_vm._v(_vm._s(items[0].date_performed)+" ")])]),_c('td',[_c('b',[_vm._v(_vm._s(items.length)+" time logs")])]),_c('td',{attrs:{"colspan":"3"}},[_c('b',[_vm._v(_vm._s(items .reduce(function (a, b) { return +a + +(b.time_in_minutes / 60); }, 0) .toFixed(2))+" ")])])]:[_c('td',[_c('div',{staticClass:"d-flex"},[_c('b',[_vm._v(_vm._s(items[0].date_performed)+" ")]),_c('v-spacer'),_c('b',[_vm._v(_vm._s(items.length)+" time logs")]),_c('v-spacer'),_c('b',[_vm._v(_vm._s(items .reduce(function (a, b) { return +a + +(b.time_in_minutes / 60); }, 0) .toFixed(2))+" hours ")])],1)])]]}},{key:"item.icon",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":item.synced ? 'success' : 'warning'}},[_vm._v(" "+_vm._s(item.synced ? "mdi-sync" : "mdi-sync-alert")+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.synced ? "Time Entry synced." : "Time Entry is pending sync with Mavenlink."))])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":item.approved
                    ? 'error'
                    : item.active_submission_id && !item.approved
                    ? 'warning'
                    : 'success'}},[_vm._v(" "+_vm._s(item.approved ? "mdi-lock-outline" : item.active_submission_id && !item.approved ? "mdi-lock-alert-outline" : "mdi-lock-open-outline")+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.approved ? "Time Entry is approved and locked." : item.active_submission_id && !item.approved ? "Time Entry was submitted for approval but has yet to be approved." : "Time Entry has not been approved yet."))])])],1)]}},{key:"item.project",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.workspace ? item.workspace.title : "Awaiting sync...")+" "),(!item.synced)?_c('v-progress-linear',{attrs:{"buffer-value":"0","stream":""}}):_vm._e()]}},{key:"item.task",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.story ? item.story.title : "")+" ")]}},{key:"item.billable",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(item.billable ? "Yes" : "No")+" ")]}},{key:"item.time_in_minutes",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(parseFloat(item.time_in_minutes / 60).toFixed(2))+"h ")]}},{key:"item.actions",fn:function(ref){
                    var item = ref.item;
return [(!item.approved)?[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteTimeLog(item)}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-delete ")])],1),_c('v-btn',{staticClass:"ml-2",attrs:{"loading":_vm.$store.state.loadingButtons.includes(item.id),"icon":""},on:{"click":function($event){return _vm.$store.dispatch('time/editLog', item.id)}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-pencil ")])],1),_c('v-btn',{staticClass:"ml-2",attrs:{"icon":"","disabled":!item.synced,"loading":_vm.$store.state.loadingButtons.includes(("copy-" + (item.id)))},on:{"click":function($event){return _vm.$store.dispatch('time/copyLog', item.id)}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-content-copy ")])],1)]:_vm._e(),(item.internalTimeLog && item.internalTimeLog.jiraIssueKey)?_c('v-btn',{staticClass:"ml-2",attrs:{"icon":"","target":"_blank","href":("https://orases.atlassian.net/browse/" + (item.internalTimeLog.jiraIssueKey) + "?focusedWorklogId=" + (item.internalTimeLog.jiraLogId))}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-jira ")])],1):_vm._e(),(
          item.internalTimeLog &&
          item.internalTimeLog.issueId &&
          !item.internalTimeLog.jiraIssueId
        )?_c('v-btn',{staticClass:"ml-2",attrs:{"icon":"","target":"_blank","href":("https://redmine.orases.com/issues/" + (item.internalTimeLog.issueId))}},[_c('img',{attrs:{"width":"25","src":"https://katalon-store.s3.amazonaws.com/nbdn34vruye216te-redmine-logo.png"}})]):_vm._e(),(item.internalTimeLog && item.internalTimeLog.eventId)?_c('v-btn',{staticClass:"ml-2",attrs:{"icon":"","target":"_blank","href":_vm.getCalendarLink(item)}},[_c('v-icon',[_vm._v(" mdi-calendar-blank ")])],1):_vm._e()]}}],null,true)}),_c('ConfirmationDialog',{ref:"confirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }