<template>
  <v-card :flat="dialogPlacement === 'sidebar'">
    <v-toolbar
      v-if="dialogPlacement !== 'sidebar'"
      color="primary"
      dark
      :rounded="false"
    >
      <v-toolbar-title>
        {{
          dialog.modify ? "Modify Time Entry" : "Add Time Entry"
        }}</v-toolbar-title
      >
      <v-spacer></v-spacer>
      <v-btn-toggle v-model="dialogPlacement" tile group>
        <v-btn value="modal"> Modal </v-btn>
        <v-btn value="sidebar"> Sidebar </v-btn>
      </v-btn-toggle>
    </v-toolbar>
    <v-card-text>
      <v-container>
        <v-alert v-if="errorMessage && errorMessage.length" type="error">
          {{ errorMessage }}
        </v-alert>
        <v-banner v-if="dialog.issue" class="mb-4" single-line>
          <v-icon slot="icon" color="warning" size="26">
            {{ enableRedmine ? "mdi-bug" : "mdi-jira" }}
          </v-icon>

          <template v-if="!enableRedmine && dialog.issue.fields">
            JIRA #{{ dialog.issue.key }} -
            {{ dialog.issue.fields.summary }}
          </template>
          <template v-else-if="enableRedmine && dialog.issue">
            Redmine #{{ dialog.issue.id }} - {{ dialog.issue.subject }}
          </template>

          <v-spacer></v-spacer>

          <template v-slot:actions>
            <template v-if="enableRedmine">
              <v-btn
                :href="`https://redmine.orases.com/issues/${dialog.issue.id}`"
                target="_blank"
                link
                color="primary"
                text
              >
                View in Redmine
              </v-btn>
            </template>
            <template v-else-if="dialog.issue.key">
              <v-btn
                :href="`https://orases.atlassian.net/browse/${dialog.issue.key}`"
                target="_blank"
                link
                color="primary"
                text
              >
                View in Jira
              </v-btn>
            </template>
          </template>
        </v-banner>
        <v-form ref="form" v-model="valid">
          <v-row>
            <v-col cols="12">
              <v-textarea
                label="Description"
                v-model="dialog.description"
                rows="3"
              ></v-textarea>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                :rules="rules.time"
                v-model="dialog.time"
                label="Time"
                @keyup="updateTime"
                required
                autocomplete="off"
                persistent-hint
                :hint="timeHint"
                :autofocus="dialog.issue ? true : false"
              >
                <template v-slot:append>
                  <v-btn
                    @click="
                      dialog.time = parseFloat(dialog.suggestedTime).toFixed(2)
                      dialog.suggestedTime = false
                    "
                    v-if="dialog.suggestedTime"
                    color="success"
                    outlined
                    x-small
                    class="font-weight-bold"
                    >+{{
                      parseFloat(dialog.suggestedTime).toFixed(2)
                    }}
                    Hours</v-btn
                  >
                </template>
              </v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-menu
                ref="menu"
                v-model="dialog.menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :rules="rules.date"
                    v-model="dialog.date"
                    label="Date"
                    persistent-hint
                    v-on="on"
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                  show-adjacent-months
                  v-model="dialog.date"
                  no-title
                  @input="dialog.menu = false"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <template v-if="issueTracker !== 'jira' || !dialog.issue">
              <v-col cols="6" md="6">
                <v-autocomplete
                  persistent-hint
                  :hint="taskHint"
                  :disabled="isTaskLocked"
                  :rules="rules.story"
                  :items="mappedStories"
                  :filter="filterContainsAllWords"
                  item-text="title"
                  item-value="id"
                  label="Task"
                  clearable
                  auto-select-first
                  v-model="dialog.task"
                  :autofocus="dialog.time && !dialog.task"
                >
                </v-autocomplete>
                <v-alert
                  v-if="
                    dialog.originalTask &&
                    currentTask &&
                    currentTask.workspace &&
                    dialog.originalTask.workspace_id != currentTask.workspace_id
                  "
                  type="warning"
                  text
                >
                  You are changing the project from
                  <b>{{ dialog.originalTask.workspace.title }}</b> to
                  <b>{{ currentTask.workspace.title }}</b
                  >.
                </v-alert>
                <div
                  v-if="
                    !dialog.task &&
                    suggestedTasks.length > 0 &&
                    suggestedTasks.length <= 8
                  "
                >
                  <label for="">Suggested Tasks:</label>
                  <v-chip-group column active-class="success--text">
                    <v-chip
                      @click="dialog.task = task.id"
                      v-for="task in suggestedTasks"
                      :key="task.id"
                    >
                      {{ task.title }}
                    </v-chip>
                  </v-chip-group>
                </div>
              </v-col>
              <v-col cols="6">
                <v-autocomplete
                  persistent-hint
                  :hint="taskHint"
                  :disabled="isTaskLocked"
                  :items="mappedProjects"
                  :filter="filterContainsAllWords"
                  item-text="title"
                  item-value="id"
                  label="Project Filter"
                  clearable
                  auto-select-first
                  v-model="workspaceId"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-checkbox
                  class="mt-0 mb-0"
                  v-model="assignedTasksFilter"
                  label="Only Show Assigned Tasks"
                ></v-checkbox>
              </v-col>
            </template>
            <v-col cols="12">
              <SpentHoursWarning
                :dialog="dialog"
                :spentPercentage="spentPercentage"
                :updateSpentHours="userPreferences.updateSpentHours"
              ></SpentHoursWarning>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
      <v-divider></v-divider>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn class="mb-4" color="warning darken-1" @click="showDialog = false"
        >Cancel</v-btn
      >
      <v-btn
        class="mb-4 mr-3"
        :loading="isLoading"
        @click="submit"
        color="success darken-1"
        >Save Entry</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import SpentHoursWarning from "@/components/info/SpentHoursWarning.vue"

import { computed, ref } from "@vue/composition-api"
import { useFind } from "feathers-vuex"
import { mapState, mapActions, mapMutations, mapGetters } from "vuex"
import { createHelpers, mapFields } from "vuex-map-fields"
import fuzzy from "fuzzy"
import moment from "moment"

const { mapFields: scopedMapFields } = createHelpers({
  getterType: "time/getField",
  mutationType: "time/updateField",
})

export default {
  name: "TimeForm",
  setup(props, context) {
    const { Story, Workspace, Priority } = context.root.$FeathersVuex.api

    const { $store } = context.root
    const { mavenlinkId } = $store.state.auth.user
    const workspaceId = ref(null)
    const assignedTasksFilter = ref(false)

    const allowQuery = computed(() => {
      return $store.state.auth.user &&
        $store.state.auth.user.mavenlinkAccessToken
        ? true
        : false
    })

    const StoryParams = computed(() => {
      return {
        query: {},
      }
    })

    const WorkspaceParams = computed(() => {
      return {
        query: {},
        debounce: 250,
      }
    })

    const { items: workspaces } = useFind({
      model: Workspace,
      params: WorkspaceParams,
    })

    const {
      items: stories,
      isPending,
      find: find,
    } = useFind({
      model: Story,
      params: StoryParams,
      queryWhen: allowQuery,
      fetchParams: null,
    })

    const PriorityParams = computed(() => {
      const { currentDate } = $store.state
      return {
        query: {
          $limit: 100,
          assignee_id: mavenlinkId,
          date: {
            $gt: moment(currentDate)
              .startOf("month")
              .subtract(1, "week")
              .format("YYYY-MM-DD"),
            $lt: moment(currentDate)
              .endOf("month")
              .add(2, "day")
              .format("YYYY-MM-DD"),
          },
        },
      }
    })

    const { items: priorities } = useFind({
      model: Priority,
      params: PriorityParams,
    })

    const updateTime = () => {
      if (!$store.state.time.timeDialog.time) {
        return
      }
      $store.state.time.timeDialog.time =
        $store.state.time.timeDialog.time.replace(/[^0-9.:]/, "")
    }

    const todaysPriorities = computed(() => {
      return (priorities.value || []).filter((priority) => {
        return priority.date === moment().format("YYYY-MM-DD")
      })
    })

    const isStoryPriority = (story) => {
      return todaysPriorities.value.some((priority) => {
        return priority.workspace_id == story.workspace_id
      })
    }

    const isWorkspacePriority = (workspace) => {
      return todaysPriorities.value.some((priority) => {
        return priority.workspace_id == workspace.id
      })
    }

    const mappedStories = computed(() => {
      return (stories.value || [])
        .filter((s) => !assignedTasksFilter.value || s.assigned_to_current_user)
        .filter(
          (s) =>
            (!workspaceId.value || s.workspace_id == workspaceId.value) &&
            s.time_trackable
        )
        .sort((a, b) => {
          return a.assigned_to_current_user > b.assigned_to_current_user
            ? -1
            : 1
        })
        .sort((a, b) => {
          return isStoryPriority(a) > isStoryPriority(b) ? -1 : 1
        })
        .map((story) => {
          const client_name =
            story.workspace && story.workspace.client_name
              ? story.workspace.client_name.includes(" - ")
                ? story.workspace.client_name.split(" - ")[1]
                : story.workspace.client_name
              : ""

          const client_acronym = client_name.length
            ? (client_name.match(/[A-Z]/g) || []).join("")
            : ""

          // Determine the user role that this task belongs to based on if it includes words like QA, DPM, Dev, etc.
          let user_role

          if (story.title.includes("DPM")) {
            user_role = "Project Manager"
          }

          if (story.title.includes("QA") || story.title.includes("Testing")) {
            user_role = "QA"
          }

          if (
            story.title.includes("Dev") ||
            story.title.includes("Development")
          ) {
            user_role = "Developer"
          }

          if (story.title.includes("BA")) {
            user_role = "Business Analyst"
          }

          return {
            id: story.id,
            title: `${story.title} - ${story.workspace.title}`,
            task_name: story.title,
            client_name,
            client_acronym,
            project_name: story.workspace.title,
            story: story,
            user_role,
            meeting_task: story.title.includes("Meeting"),
          }
        })
    })

    const mappedProjects = computed(() => {
      return (workspaces.value || [])
        .sort((a, b) => {
          return isWorkspacePriority(a) > isWorkspacePriority(b) ? -1 : 1
        })
        .map((workspace) => {
          return {
            id: workspace.id,
            title: `${workspace.title}`,
          }
        })
    })

    // Filter function for autocomplete to filter by all words, regardess of their position
    const filterContainsAllWords = (item, queryText, itemText) => {
      const searchText = queryText.toLowerCase()
      const itemTextLower = itemText.toLowerCase()
      // split search into array of words, and return true as long as each word exists in the item text
      const words = searchText.split(" ").filter((w) => !!w.trim().length)
      return words.every((word) => itemTextLower.includes(word))
    }

    return {
      mappedStories,
      mappedProjects,
      stories,
      isPending,
      find,
      updateTime,
      filterContainsAllWords,
      workspaceId,
      assignedTasksFilter,
    }
  },
  computed: {
    ...scopedMapFields({
      dialog: "timeDialog",
      showDialog: "timeDialog.show",
    }),
    ...mapGetters("time", [
      "hasSpentHoursField",
      "spentHours",
      "spentPercentage",
      "timeHint",
    ]),
    ...mapFields("userPreferences", ["dialogPlacement"]),
    ...mapFields({
      updateSpentHours: "userPreferences.updateSpentHours",
      userRole: "userRole",
    }),
    ...mapState({
      errorMessage: (state) => state.errorMessage,
    }),
    ...mapState(["userPreferences"]),
    ...mapState("auth", ["user"]),
    issueTracker() {
      return this.userPreferences.issueTracker
    },
    enableRedmine() {
      return this.userPreferences.issueTracker === "redmine"
    },
    fields() {
      if (!this.model) return []

      return Object.keys(this.model).map((key) => {
        return {
          key,
          value: this.model[key] || "n/a",
        }
      })
    },
    items() {
      return this.entries.map((entry) => {
        const title =
          entry.title.length > this.titleLimit
            ? entry.title.slice(0, this.titleLimit) + "..."
            : entry.title

        return Object.assign({}, entry, { title })
      })
    },
    isTaskLocked() {
      const currentTask = this.$store.getters["mavenlink/story/get"](
        this.dialog.story
      )

      if (currentTask && currentTask.is_jira_issue) {
        return true
      }
      return false
    },
    taskHint() {
      return this.isTaskLocked
        ? "Jira tasks cannot be changed after they are saved. If you need to change the task, please delete this entry and create a new one."
        : ""
    },
    currentTask() {
      return this.$store.getters["mavenlink/story/get"](this.dialog.task)
    },
    suggestedTasks() {
      const currentUserRole =
        this.userRole && this.userRole.name ? this.userRole.name : ""
      const taskMatchString = this.dialog.taskMatchString

      if (!taskMatchString) {
        return []
      }

      // Get all unique client_acronym from this.mappedStories
      const allClientAcronyms = this.mappedStories.map(
        (story) => story.client_acronym
      )

      // Filter out any duplicates
      const clientAcronym = [...new Set(allClientAcronyms)]

      const matchedClientAcronym = clientAcronym.find((client_acronym) => {
        return taskMatchString.includes(client_acronym)
      })

      const filteredStories = this.mappedStories.filter((story) => {
        return (
          (story.user_role === undefined ||
            currentUserRole.includes(story.user_role)) &&
          (matchedClientAcronym
            ? story.client_acronym === matchedClientAcronym
            : true) &&
          (this.dialog.event
            ? story.meeting_task || story.meeting_task === undefined
            : true) &&
          (this.dialog.isInternal ? story.client_name.includes("Orases") : true)
        )
      })

      return filteredStories
    },
  },
  data: () => ({
    rules: {
      time: [(v) => !!v || "Time is required"],
      date: [(v) => !!v || "Date is required"],
      story: [(v) => !!v || "Task is required"],
    },
    valid: true,
    descriptionLimit: 60,
    entries: [],
    isLoading: false,
    model: null,
    search: null,
    redmineIssue: false,
  }),
  mounted() {
    this.valid = true
  },
  methods: {
    ...mapMutations(["hideModal"]),
    ...mapActions("auth", ["logout"]),
    ...mapActions(["getTimeFromString"]),
    ...mapActions({
      logTimeToMavenlink: "time/logTimeToMavenlink",
    }),
    selectRedmine(value) {
      if (this.issueTracker === "jira" || !this.enableRedmine) {
        this.$store.dispatch("jira/issue/get", value.id).then((issue) => {
          this.search = ""
          this.dialog.issue = issue
          if (!this.dialog.description) {
            this.dialog.description = `#${issue.key} - ${issue.fields.summary}`
          }
        })
      } else {
        this.$store.dispatch("redmine/issue/get", value.id).then((issue) => {
          this.search = ""
          this.dialog.issue = issue
          if (!this.dialog.description) {
            this.dialog.description = `#${issue.id} - ${issue.subject}`
          }
        })
      }
    },
    filterRedmineSearch: (item, queryText, itemText) =>
      fuzzy.match(queryText, itemText, {
        caseSensitive: false,
      }),
    submit() {
      // Don't allow submitting twice.
      if (this.isLoading) {
        return false
      }

      this.isLoading = true
      if (this.$refs.form.validate()) {
        this.logTimeToMavenlink()
          .then(() => {
            this.isLoading = false
          })
          .catch(() => {
            this.isLoading = false
          })
      } else {
        this.isLoading = false
      }
    },
  },
  watch: {
    search(val) {
      if (!val) return
      if (val === "") return

      // Items have already been requested
      if (this.isLoading) return

      val = val.replace("#", "")

      if (val.length < 5) return

      this.isLoading = true

      if (val.length === 5) {
        this.$store
          .dispatch("redmine/issue/get", val)
          .then((issue) => {
            this.entries = [
              {
                title: `#${issue.id} - ${issue.subject}`,
                id: issue.id,
              },
            ]
            this.isLoading = false
          })
          .finally(() => {
            this.isLoading = false
          })
      } else {
        // Lazily load input items
        this.$store
          .dispatch("redmine/search/find", { query: { q: val, $limit: 100 } })
          .then((response) => {
            if (response && response.length) {
              this.entries = response.filter((item) => {
                return item.type.includes("issue")
              })
              this.isLoading = false
            }
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    },
  },
  components: { SpentHoursWarning },
}
</script>
<style>
.v-banner__wrapper {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
</style>
