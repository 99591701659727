<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="primary" dark>
        <v-toolbar-title>Priorities</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-progress-linear
          :active="isPending"
          :indeterminate="isPending"
          absolute
          bottom
          color="accent"
        ></v-progress-linear>
        <v-spacer></v-spacer>
        <v-btn outlined class="mr-4" @click="setToday"> Today </v-btn>
        <v-btn fab text small @click="prev">
          <v-icon small> mdi-chevron-left </v-icon>
        </v-btn>
        {{ currentMonth }}
        <v-btn fab text small @click="next">
          <v-icon small> mdi-chevron-right </v-icon>
        </v-btn>
      </v-toolbar>
      <v-sheet height="700">
        <v-calendar
          title="Priorities"
          ref="calendar"
          v-model="currentDate"
          :weekdays="[1, 2, 3, 4, 5, 6, 0]"
          :type="type"
          :events="events"
          :event-color="getEventColor"
          :event-overlap-threshold="30"
        ></v-calendar>
      </v-sheet>
    </v-card>
  </v-container>
</template>

<script>
import { computed } from "@vue/composition-api"
import { useFind } from "feathers-vuex"
import { mapFields } from "vuex-map-fields"
import { mapGetters } from "vuex"
import moment from "moment"
export default {
  name: "Priorities",
  setup(props, context) {
    const { Priority, TimeOffEntry, Holiday } = context.root.$FeathersVuex.api
    let { $store } = context.root
    const { mavenlinkId } = $store.state.auth.user

    const PriorityParams = computed(() => {
      const { currentDate } = $store.state
      return {
        query: {
          $limit: 100,
          assignee_id: mavenlinkId,
          date: {
            $gt: moment(currentDate)
              .startOf("month")
              .subtract(1, "week")
              .format("YYYY-MM-DD"),
            $lt: moment(currentDate)
              .endOf("month")
              .add(2, "day")
              .format("YYYY-MM-DD"),
          },
        },
      }
    })

    const TimeOffEntryParams = computed(() => {
      return {
        query: {
          $limit: 100,
          user_id: mavenlinkId,
        },
      }
    })

    const HolidayParams = computed(() => {
      return {
        query: {
          $limit: 200,
          $sort: { start_date: -1 },
        },
      }
    })

    const { items: timeOffEntries } = useFind({
      model: TimeOffEntry,
      params: TimeOffEntryParams,
    })

    const { items, isPending, find } = useFind({
      model: Priority,
      params: PriorityParams,
    })

    const { items: holidays } = useFind({
      model: Holiday,
      params: HolidayParams,
    })

    const events = computed(() => {
      const allEvents = []

      allEvents.push(
        ...items.value.map((priority) => {
          return {
            name: `(${priority.minutes / 60} hrs) ${priority.workspace_title}`,
            start: priority.date,
            end: priority.date,
            timed: false,
            color: "blue",
            owner: "N/A",
            client: "N/A",
            hours: priority.minutes * 60,
          }
        })
      )

      allEvents.push(
        ...timeOffEntries.value.map((entry) => {
          return {
            name: `(${entry.hours} hrs) - Paid Time Off`,
            start: entry.requested_date,
            end: entry.requested_date,
            timed: false,
            color: "orange",
            owner: "N/A",
            client: "N/A",
            hours: entry.hours,
          }
        })
      )

      allEvents.push(
        ...holidays.value.map((holiday) => {
          return {
            name: `(${holiday.total_hours} hrs) - ${holiday.name}`,
            start: holiday.start_date,
            end: holiday.start_date,
            timed: false,
            color: "green",
            owner: "N/A",
            client: "N/A",
            hours: holiday.total_hours,
          }
        })
      )

      return allEvents
    })

    const typeToLabel = {
      month: "Month",
      week: "Week",
      day: "Day",
      "4day": "4 Days",
    }

    return {
      items,
      isPending,
      find,
      events,
      PriorityParams,
      typeToLabel,
    }
  },
  computed: {
    ...mapGetters(["currentMonth"]),
    ...mapFields(["currentDate"]),
  },
  data: () => ({
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    type: "month",
    types: ["month", "week"],
    mode: "stack",
    weekday: [1, 2, 3, 4, 5, 6, 0],
  }),
  methods: {
    getEventColor(event) {
      return event.color
    },
    viewDay({ date }) {
      this.focus = date
      this.type = "day"
    },
    setToday() {
      this.currentDate = moment().format("YYYY-MM-DD")
    },
    prev() {
      this.$refs.calendar.prev()
    },
    next() {
      this.$refs.calendar.next()
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        setTimeout(() => {
          this.selectedOpen = true
        }, 10)
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        setTimeout(open, 10)
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },

    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    },
  },
}
</script>
