<template>
  <v-row justify="center">
    <v-dialog
      overlay-opacity="1"
      width="50%"
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title class="headline">{{ headline }}</v-card-title>
        <v-card-text v-if="authChecked">
          <div>
            {{ welcomeMessage }}
          </div>
        </v-card-text>
        <v-card-text v-else>
          <v-progress-linear
            color="blue accent-4"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-card-text>
        <v-card-actions v-if="authChecked">
          <v-spacer></v-spacer>
          <v-btn @click="login" color="green darken-1" text>Login</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapState } from "vuex"

export default {
  name: "LoginModal",
  data: () => ({
    welcomeMessage:
      "To use Time Helper, you must log in with an Orases Google account.",
  }),
  computed: {
    ...mapState({
      authChecked: (state) => state.authChecked,
      dialog: (state) => state.loginModal,
      user: (state) => state.auth.user,
    }),
    headline() {
      const messages = [
        "...at least you're not on hold...",
        "The bits are flowing slowly today",
        "Preparing to save you time...",
        "Just doing my thing...",
        "Generating witty dialog...",
        "(Insert quarter)",
        "Spinning the wheel of fortune...",
        "Cleaning off the cobwebs...",
        "Your time is very important to us. Please wait while we ignore you...",
      ].map((item) => item)

      if (!this.authChecked) {
        return messages[Math.floor(Math.random() * messages.length)]
      }

      return this.user
        ? `Welcome aboard, ${this.user.firstName}!`
        : "Login with Google"
    },
  },
  methods: {
    login() {
      window.location.href = `${process.env.VUE_APP_LOGIN_URL}oauth/google`
    },
  },
  mounted() {
    this.$store
      .dispatch("auth/authenticate")
      .then((auth) => {
        let { user } = auth
        if (!user.mavenlinkAccessToken) {
          this.$store.commit("toggleInitialSetup", true)
        }
      })
      .catch((err) => {
        this.$store.commit("toggleAuthChecked", true)
        window.localStorage.removeItem("feathers-jwt")
        console.log(err)
      })
  },
}
</script>
<style lang="scss" scoped>
.login-user-card {
  border-radius: 5px;
  width: 50%;
}
</style>
