import Vue from "vue"
import Vuex from "vuex"
import { FeathersVuex } from "../feathers-client"
import auth from "./store.auth"
import moment from "moment"
import createPersistedState from "vuex-persistedstate"
import preferences from "./preferences"
import time from "./time"
import { getField, updateField } from "vuex-map-fields"

Vue.use(Vuex)
Vue.use(FeathersVuex)

const requireModule = require.context(
  // The path where the service modules live
  "./services",
  // Whether to look in subfolders
  true,
  // Only include .js files (prevents duplicate imports`)
  /.js$/
)
const servicePlugins = requireModule
  .keys()
  .map((modulePath) => requireModule(modulePath).default)

export default new Vuex.Store({
  modules: {
    userPreferences: preferences,
    time: time,
  },
  state: {
    priorityDialog: {
      show: false,
      maps: [],
      date: "",
    },
    jiraPage: 1,
    redminePage: 1,
    redmineTab: 0,
    redmineSearchTerm: "",
    mavenlinkPage: 1,
    mavenlinkTab: 0,
    mavenlinkSearchTerm: "",
    calendarSearchTerm: "",
    jiraSearchTerm: "",
    updates: 0,
    errorMessage: "",
    currentTime: "",
    currentWeek: "",
    currentMoment: moment(),
    toastMessage: "",
    toastTimeout: 3000,
    toastColor: "success",
    showToast: false,
    loadingButtons: [],
    showInitialSetup: false,
    authChecked: false,
    modalVisible: false,
    loginModal: true,
    eventFilter: 0,
    summaryNavigation: 1,
    weekTab: 1,
    weeklySummary: [],
    eventsStart: null,
    eventsEnd: null,
    currentDate: moment().format("YYYY-MM-DD"),
    priorityFilter: "",
    redmineUserId: "",
    jiraProject: "SWC",
    jiraTab: 0,
    enableRedmine: true,
    userRole: {},
    startDate: moment().startOf("week").format("YYYY-MM-DD"),
    endDate: moment().endOf("week").format("YYYY-MM-DD"),
  },
  getters: {
    isCurrentWeek(state) {
      return state.endDate === moment().endOf("week").format("YYYY-MM-DD")
    },
    isNextWeekAvailable(state) {
      return state.endDate < moment().endOf("week").format("YYYY-MM-DD")
    },
    loginUrl() {
      return process.env.VUE_APP_LOGIN_URL
    },
    userIsOnboarded: (state, getters) => {
      const user = getters["auth/user"]

      if (!user) return false

      const issueTrackerConfigured = user.jiraApiKey &&
        user.jiraApiKey.length > 0 &&
        user.redmineApiKey &&
        user.redmineApiKey.length > 0

      const isOnboarded =
        user &&
        user.mavenlinkId &&
        user.mavenlinkId > 0 &&
        user.mavenlinkAccessToken &&
        user.mavenlinkAccessToken.length > 0 &&
        (issueTrackerConfigured || user.disableIssueTracker)

      return isOnboarded
    },
    currentMonth: (state) => moment(state.currentDate).format("MMMM, YYYY"),
    calendarParams: (state) => {
      return {
        query: {
          start: {
            dateTime: {
              $lt: state.eventsEnd,
              $gt: state.eventsStart,
            },
          },
        },
      }
    },
    priorityParams: (state) => {
      const { mavenlinkId } = state.auth.user

      return {
        query: {
          $limit: 100,
          assignee_id: mavenlinkId,
          date: {
            $gte: state.startDate,
            $lte: state.endDate,
          },
        },
      }
    },
    getField,
  },
  mutations: {
    SET_REDMINE_USER_ID(state, payload) {
      state.redmineUserId = payload
    },
    SET_REDMINE_PAGE(state, payload) {
      state.redminePage = payload
    },
    SET_REDMINE_TAB(state, payload) {
      state.redmineTab = payload
      state.redminePage = 1
    },
    SET_REDMINE_SEARCH_TERM(state, payload) {
      state.redmineSearchTerm = payload
      state.redminePage = 1
    },
    SET_MAVENLINK_USER_ID(state, payload) {
      state.mavenlinkUserId = payload
    },
    SET_MAVENLINK_PAGE(state, payload) {
      state.mavenlinkPage = payload
    },
    SET_MAVENLINK_TAB(state, payload) {
      state.mavenlinkTab = payload
      state.mavenlinkPage = 1
    },
    SET_MAVENLINK_SEARCH_TERM(state, payload) {
      state.mavenlinkSearchTerm = payload
      state.mavenlinkPage = 1
    },
    SET_CALENDAR_SEARCH_TERM(state, payload) {
      state.calendarSearchTerm = payload
    },
    SET_CURRENT_WEEK(state, payload) {
      state.currentWeek = payload
    },
    SET_EVENTS_START(state, payload) {
      state.eventsStart = payload
    },
    SET_EVENTS_END(state, payload) {
      state.eventsEnd = payload
    },
    REMOVE_LOADING_BUTTON(state, payload) {
      state.loadingButtons = state.loadingButtons.filter(
        (item) => item !== payload
      )
    },
    ADD_LOADING_BUTTON(state, payload) {
      state.loadingButtons.push(payload)
    },
    toggleInitialSetup(state, payload) {
      state.showInitialSetup = payload
    },
    toggleLoginModal(state, payload) {
      state.loginModal = payload
    },
    toggleAuthChecked(state, payload) {
      state.authChecked = payload
    },
    updateWeeklySummary(state, payload) {
      state.weeklySummary = payload
    },
    SET_SUMMARY_NAVIGATION(state, payload) {
      state.summaryNavigation = payload
    },
    SET_TOAST_MESSAGE(state, payload) {
      state.toastMessage = payload
    },
    SET_TOAST_VISIBILITY(state, payload) {
      state.showToast = payload
    },
    SET_TOAST_COLOR(state, payload) {
      state.toastColor = payload
    },
    SET_TOAST_TIMEOUT(state, payload) {
      state.toastTimeout = payload
    },
    UPDATE_CURRENT_TIME(state, payload) {
      state.currentTime = payload
    },
    UPDATE_CURRENT_MOMENT(state, payload) {
      state.currentMoment = payload
    },
    SET_ERROR_MESSAGE(state, payload) {
      state.errorMessage = payload
    },
    SET_JIRA_PROJECT(state, payload) {
      state.jiraProject = payload
    },
    SET_JIRA_PAGE(state, payload) {
      state.jiraPage = payload
    },
    SET_JIRA_TAB(state, payload) {
      state.jiraTab = payload
    },
    SET_JIRA_SEARCH_TERM(state, payload) {
      state.jiraSearchTerm = payload
    },
    updateField,
  },
  actions: {
    filterByPriority: ({ getters, state }, date) => {
      let dateSummary = getters["priorities/list"].find(
        (priority) => priority.date === date
      )

      let needsMapping = dateSummary.priorities
        .filter((priority) => !priority.redmine_id)
        .map((priority) => {
          return {
            project_id: priority.project_id,
            redmine_id: 0,
            project: priority.project,
          }
        })

      let redmineIds = dateSummary.priorities
        .filter((priority) => priority.redmine_id)
        .map((priority) => priority.redmine_id)

      if (needsMapping.length) {
        state.priorityDialog = {
          maps: needsMapping,
          show: true,
          date,
        }
      } else {
        if (date === state.priorityFilter) {
          // Clear filter
          state.priorityFilter = ""
          state.userPreferences.redmineProjectFilter = []
        } else {
          state.priorityFilter = date
          state.userPreferences.redmineProjectFilter = redmineIds
        }
      }
    },
    updateCurrentTime: ({ commit, state }) => {
      commit("UPDATE_CURRENT_TIME", moment().format("ddd, MMMM Do, h:mm A"))
      return state.currentTime
    },
    updateCurrentMoment: ({ commit, state }) => {
      commit("UPDATE_CURRENT_MOMENT", moment())
      return state.currentMoment
    },
    showToastSuccess: ({ commit, state }, newValue) => {
      commit("SET_TOAST_MESSAGE", newValue)
      commit("SET_TOAST_TIMEOUT", 3000)
      commit("SET_TOAST_VISIBILITY", true)
      commit("SET_TOAST_COLOR", "success")
      return state.toastMessage
    },
    showToastError: ({ commit, state }, newValue) => {
      commit("SET_TOAST_MESSAGE", newValue)
      commit("SET_TOAST_TIMEOUT", 6000)
      commit("SET_TOAST_VISIBILITY", true)
      commit("SET_TOAST_COLOR", "error")

      return state.toastMessage
    },
    toggleToast: ({ commit, state }, newValue) => {
      commit("SET_TOAST_VISIBILITY", newValue)

      return state.showToast
    },
    loadButton: ({ commit, state }, newValue) => {
      commit("ADD_LOADING_BUTTON", newValue)
      return state.loadingButtons
    },
    unloadButton: ({ commit, state }, newValue) => {
      commit("REMOVE_LOADING_BUTTON", newValue)
      return state.loadingButtons
    },
    setSummaryNavigation: ({ commit, state }, newValue) => {
      commit("SET_SUMMARY_NAVIGATION", newValue)
      return state.summaryNavigation
    },

    updateEventsStart: ({ commit, state }, newValue) => {
      commit("SET_EVENTS_START", newValue)
      return state.eventsStart
    },
    updateEventsEnd: ({ commit, state }, newValue) => {
      commit("SET_EVENTS_END", newValue)
      return state.eventsEnd
    },
    updateCurrentWeek: ({ commit, state }, newValue) => {
      commit("SET_CURRENT_WEEK", newValue)
      return state.currentWeek
    },
    updateRedmineSearchTerm: ({ commit, state }, newValue) => {
      commit("SET_REDMINE_SEARCH_TERM", newValue)
      return state.redmineSearchTerm
    },
    updateRedmineTab: ({ commit, state }, newValue) => {
      commit("SET_REDMINE_TAB", newValue)
      return state.redmineTab
    },
    updateRedminePage: ({ commit, state }, newValue) => {
      commit("SET_REDMINE_PAGE", newValue)
      return state.redminePage
    },
    updateMavenlinkSearchTerm: ({ commit, state }, newValue) => {
      commit("SET_MAVENLINK_SEARCH_TERM", newValue)
      return state.redmineSearchTerm
    },
    updateMavenlinkTab: ({ commit, state }, newValue) => {
      commit("SET_MAVENLINK_TAB", newValue)
      return state.redmineTab
    },
    updateMavenlinkPage: ({ commit, state }, newValue) => {
      commit("SET_MAVENLINK_PAGE", newValue)
      return state.redminePage
    },
    updateCalendarSearchTerm: ({ commit, state }, newValue) => {
      commit("SET_CALENDAR_SEARCH_TERM", newValue)
      return state.calendarSearchTerm
    },
    updateJiraProject: ({ commit, state }, newValue) => {
      commit("SET_JIRA_PROJECT", newValue)
      return state.jiraProject
    },
  },
  plugins: [
    ...servicePlugins,
    auth,
    createPersistedState({
      paths: ["userPreferences"],
    }),
  ],
})
