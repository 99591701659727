<template>
  <div id="today-nav" transition="slide-y-transition">
    <v-expansion-panels
      v-if="showTodayWidget"
      box
      active-class="today-nav-expanded"
      v-model="todayPanel"
    >
      <v-expansion-panel>
        <v-expansion-panel-header
          >Today: {{ timeLogs.length }} Time Log{{
            timeLogs.length > 1 ? "s" : ""
          }}</v-expansion-panel-header
        >
        <v-expansion-panel-content style="padding: 0px">
          <v-list v-model="taskItem" nav dense>
            <template v-for="(log, index) in timeLogs">
              <v-divider :key="index" v-if="index !== 0" />
              <template>
                <v-list-item :key="log.id" two-line>
                  <v-list-item-content>
                    <v-list-item-title class="text-truncate">
                      <b
                        >{{
                          parseFloat(log.time_in_minutes / 60).toFixed(2)
                        }}h</b
                      >
                      {{ log.notes }}</v-list-item-title
                    >
                    <v-list-item-subtitle>
                      {{ log.workspace ? log.workspace.title + " - " : "" }}
                      {{ log.story ? log.story.title : "" }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-speed-dial direction="left" :open-on-hover="true">
                      <template v-slot:activator>
                        <v-btn x-small color="primary" dark fab>
                          <v-icon>mdi-clock</v-icon>
                        </v-btn>
                      </template>
                      <v-btn
                        @click="$store.dispatch('time/editLog', log.id)"
                        fab
                        dark
                        small
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                      <v-btn
                        @click="$store.dispatch('time/copyLog', log.id)"
                        fab
                        dark
                        small
                        v-if="log.synced"
                      >
                        <v-icon>mdi-content-copy</v-icon>
                      </v-btn>
                      <v-btn
                        @click="deleteTimeLog(log)"
                        fab
                        dark
                        small
                        color="red"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-speed-dial>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </template>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <ConfirmationDialog ref="confirm"></ConfirmationDialog>
  </div>
</template>

<script>
import { computed } from "@vue/composition-api"
import { useFind } from "feathers-vuex"
import moment from "moment"
import ConfirmationDialog from "./ConfirmationDialog"
import { mapFields } from "vuex-map-fields"
export default {
  name: "TodayNav",
  computed: {
    ...mapFields("userPreferences", ["showTodayWidget"]),
  },
  setup(props, context) {
    const { TimeEntry } = context.root.$FeathersVuex.api
    const { refs: $refs } = context
    let { $store } = context.root

    const user = computed(() => {
      const { user } = $store.state.auth
      return user
    })

    const TimeFetchParams = computed(() => {
      const { mavenlinkId } = $store.state.auth.user
      let { updates } = $store.state

      const params = {
        updates: updates,
        query: {
          $sort: {
            date_performed: -1,
          },
          ...(mavenlinkId ? { user_id: `${mavenlinkId}` } : {}),
        },
      }
      return params
    })

    const TimeParams = computed(() => {
      const { mavenlinkId } = $store.state.auth.user
      let { updates } = $store.state
      let today = moment()
      const params = {
        updates: updates,
        query: {
          $sort: {
            date_performed: -1,
          },
          ...(mavenlinkId ? { user_id: `${mavenlinkId}` } : {}),
          date_performed: {
            $lt: today.add(1, "day").format("YYYY-MM-DD"),
            $gt: today.subtract(2, "day").format("YYYY-MM-DD"),
          },
        },
      }
      return params
    })

    const {
      items: timeLogs,
      isPending,
      find,
    } = useFind({
      model: TimeEntry,
      params: TimeParams,
      fetchParams: TimeFetchParams,
    })

    const deleteTimeLog = (log) => {
      $refs.confirm
        .open(
          "Confirmation",
          `Are you sure you want to delete the time log <b>${
            log.notes || "No Description"
          }</b>?`,
          { color: "red" }
        )
        .then((confirm) => {
          if (confirm) {
            $store
              .dispatch("mavenlink/time-entry/remove", log.id)
              .then(() => {
                //TODO: Make this DRY
                let calendarParams = {
                  query: {
                    start: {
                      dateTime: {
                        $lt: $store.state.eventsEnd,
                        $gt: $store.state.eventsStart,
                      },
                    },
                  },
                }
                $store.dispatch("google/calendar/find", calendarParams)
                $store.dispatch("showToastSuccess", "Time log deleted.")
              })
              .catch((err) => {
                $store.dispatch(
                  "showToastError",
                  `Error deleting time log. ${err.error}`
                )
              })
          }
        })
    }

    const todaysTotal = computed(() => {
      return timeLogs.value.reduce((a, b) => a + b.time, 0)
    })

    const todayPanel = computed({
      get: () => $store.state.userPreferences.showTodayPanel,
      set: (value) =>
        $store.dispatch("userPreferences/toggleTodayPanel", value),
    })

    // Update every hour
    setInterval(() => {
      $store.state.updates++
    }, 1000 * 60 * 60)

    return {
      timeLogs,
      deleteTimeLog,
      isPending,
      find,
      user,
      todaysTotal,
      todayPanel,
    }
  },
  data() {
    return { taskItem: [], timeFab: "" }
  },
  components: {
    ConfirmationDialog,
  },
}
</script>
<style>
.v-expansion-panel-content__wrap {
  padding: 0px !important;
}
.v-expansion-panel--active .v-expansion-panel-header__icon i {
  transform: rotate(0deg) !important;
}
.v-expansion-panel:not([aria-expanded="true"])
  .v-expansion-panel-header__icon
  i {
  transform: rotate(180deg) !important;
}
/* #today-nav {
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
} */
</style>
