import { unescape } from "html-escaper"
import moment from 'moment'
const helperMixin = {
  methods: {
    unescapeTitle: function(obj) {
      return obj.title ? unescape(obj.title) : ""
    },
    unescape: function(text) {
      return unescape(text)
    },
    formatDate: function (date) {
      return moment(date).format('MM/DD/YYYY')
    },
    formatDateHuman: function (date) {
      // Format the date in the format of "January 1, 2020"
      return moment(date).format('MMMM D, YYYY')
    }
  }
}

export default helperMixin
